import { combineReducers } from "redux";
import accountReducer from "./Account/redux";
import ranchReducer from "./Ranch/redux";

const rootReducer = combineReducers({
	account: accountReducer,
	ranch: ranchReducer,
	lastAction: (state = null, action) => {
		return action;
	},
	storeStatus: (state = null, action) => {
		switch (action.type) {
			case "STATE_STATUS":
				return action.payload;
			default:
				return state;
		}
	},
	needRefresh: (state = null, action) => {
		switch (action.type) {
			case "NEED_REFRESH":
				return action.payload;
			default:
				return state;
		}
	},
	reset: (state = null, action) => {
		switch (action.type) {
			case "RESET_ALL":
				window.location.reload();

				return null;
			default:
				return state;
		}
	},
});
export default rootReducer;
