import React, { useState } from "react";
import { Button, FormGroup, Intent } from "@blueprintjs/core";
import TreatmentSelect from "./TreatmentSelect";
import * as Classes from "@blueprintjs/core/lib/cjs/common/classes";
import AddableInput from "./AddableInput";

function AddTreatment({ addTreatment, property }) {
	const [treatmentType, setTreatmentType] = useState("V");
	const [treatment, setTreatment] = useState("");
	const [treatmentDesc, setTreatmentDesc] = useState("");

	let label = "Medication";
	if (treatmentType === "T") {
		label = "Procedure";
	}
	return (
		<div style={{ padding: "20px" }} className={"treatment"}>
			<div>
				<TreatmentSelect value={treatmentType} onChange={(e) => setTreatmentType(e.target.value)} />
			</div>
			{treatmentType === "I" && (
				<div>
					<FormGroup labelFor="name" label={"Diagnosis"} inline={true}>
						<AddableInput
							value={treatmentDesc}
							onChange={(val) => setTreatmentDesc(val)}
							options={property.getTreatmentDescriptions(treatmentType)}
						/>
					</FormGroup>
				</div>
			)}
			<div>
				<FormGroup labelFor="name" label={label} inline={true}>
					<AddableInput
						value={treatment}
						onChange={(val) => setTreatment(val)}
						options={property.getTreatments(treatmentType)}
					/>
				</FormGroup>
			</div>
			<div className={"right"}>
				<Button
					className={Classes.POPOVER_DISMISS}
					disabled={treatment === ""}
					intent={Intent.PRIMARY}
					onClick={() => {
						if (treatment === "") {
							return;
						}
						addTreatment(treatmentType, treatment, treatmentDesc);
					}}
				>
					Save
				</Button>
			</div>
			<br />
			<br />
		</div>
	);
}

export default AddTreatment;
