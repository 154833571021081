import React from "react";
import { FormGroup, HTMLSelect } from "@blueprintjs/core";

function TreatmentSelect({ value, onChange, style }) {
	return (
		<FormGroup labelFor="" label={"Type"} inline={true} style={style}>
			<HTMLSelect className={".bp3-fill"} value={value} onChange={onChange}>
				<option value={"V"}>Vaccine</option>
				<option value={"T"}>Procedure</option>
				<option value={"I"}>Diagnose</option>
			</HTMLSelect>
		</FormGroup>
	);
}

export default TreatmentSelect;
