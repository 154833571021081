import React from "react";
import { FormGroup, HTMLSelect } from "@blueprintjs/core";

function WeightSelect({ value, type, onChange, style }) {
	let types = ["Sell", "Other"];
	if (type.startsWith("Calf")) {
		types.push("Birth");
		types.push("Ween");
	}
	return (
		<FormGroup labelFor="" label={"Type"} inline={true} style={style}>
			<HTMLSelect className={".bp3-fill"} value={value} onChange={onChange}>
				{types.map((t) => (
					<option key={t} value={t}>
						{t}
					</option>
				))}
			</HTMLSelect>
		</FormGroup>
	);
}

export default WeightSelect;
