import React from "react";

import Page from "./components/Page";
import { Button } from "@blueprintjs/core";

function Log() {
	const copy = () => {
		const el = document.createElement("textarea");
		el.value = window.logOutput.join("\n");
		document.body.appendChild(el);
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
	};
	return (
		<Page header={`Debug Log`} className={"Log"}>
			<Button onClick={copy}>Copy</Button>
			<table>
				<tbody>
					{window.logOutput &&
						window.logOutput.map((log, idx) => (
							<tr key={idx}>
								<td style={{ textAlign: "left", borderBottom: "1px solid #ccc" }}>{log}</td>
							</tr>
						))}
				</tbody>
			</table>
		</Page>
	);
}

export default Log;
