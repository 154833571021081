import React, { useState } from "react";

import { Button, Intent } from "@blueprintjs/core";
import { bindActionCreators } from "redux";
import { selectors as ranchSelectors } from "./redux";
import { connect } from "react-redux";
import Page from "../components/Page";
import { createStructuredSelector } from "reselect";

import "./index.scss";
import BulkWorking from "./BulkWorking";
import Receive from "./Receive";
import BulkStatus from "./BulkStatus";
import { NavLink } from "react-router-dom";

function Processing({ property, match, processingList }) {
	const herdID = Number(match.params.herdID);
	const herd = property.getHerd(herdID);
	const [panel, setPanel] = useState(herd.getCows().length === 0 ? "receive" : match.params.tab);

	const cows = herd.getCows();
	if (cows.length === 0) {
		processingList = [];
	}

	return (
		<Page
			header={`Working for ${herd.data.name} - ${property.data.name}`}
			className={"Processing"}
			backLink={cows.length > 0 ? `/herd/${herdID}` : `/property/${property.data.ID}`}
		>
			<div className={"flex"}>
				<NavLink style={{ width: "100%" }} to={`/herd/${herd.data.ID}/process/receive`}>
					<Button
						style={{ width: "100%" }}
						intent={panel === "receive" ? Intent.PRIMARY : Intent.NONE}
						onClick={() => setPanel("receive")}
					>
						Receive
					</Button>
				</NavLink>
				<NavLink style={{ width: "100%" }} to={`/herd/${herd.data.ID}/process/working`}>
					<Button
						style={{ width: "100%" }}
						disabled={!processingList || processingList.length === 0}
						intent={panel === "working" ? Intent.PRIMARY : Intent.NONE}
						onClick={() => setPanel("working")}
					>
						Working
					</Button>
				</NavLink>
				<NavLink style={{ width: "100%" }} to={`/herd/${herd.data.ID}/process/sell`}>
					<Button
						style={{ width: "100%" }}
						disabled={herd.getCows().length === 0}
						intent={panel === "sell" ? Intent.PRIMARY : Intent.NONE}
						onClick={() => setPanel("sell")}
					>
						Sell
					</Button>
				</NavLink>
			</div>
			<br />

			{panel === "working" && (
				<div>
					<BulkWorking herdID={herdID} />
				</div>
			)}
			{panel === "receive" && (
				<div>
					<Receive herdID={herdID} />
				</div>
			)}
			{panel === "sell" && (
				<div>
					<BulkStatus herdID={herdID} />
				</div>
			)}
		</Page>
	);
}

const mapStateToProps = createStructuredSelector({
	property: ranchSelectors.property,
	processingList: ranchSelectors.processingList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Processing);
