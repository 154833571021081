import React, { useEffect, useState } from "react";

import { Button, FormGroup, HTMLSelect, Icon, InputGroup, Intent, Popover } from "@blueprintjs/core";
import { bindActionCreators } from "redux";
import { actions, selectors as ranchSelectors } from "./redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";

import "./index.scss";
import * as Classes from "@blueprintjs/core/lib/cjs/common/classes";
import AddTreatment from "./components/AddTreatment";
import C from "../Constants";

function Receive({ property, addCows, setProcessingList, herdID }) {
	const herd = property.getHerd(herdID);

	const history = useHistory();
	const STORAGE_KEY = `receiveTemp_${herdID}`;
	const STORAGE_KEY_TREATMENT = `bulkWorkingTemp_${herdID}_treatment`;

	const uuidv4 = () => {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			const r = (Math.random() * 16) | 0,
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	};

	const getEmptyCow = (existingCows = []) => {
		const c = existingCows[existingCows.length - 1];
		return {
			data: {
				status: "Open",
				tag: "",
				guid: uuidv4(),
				herdID: herdID,
				marking: c ? c.data.marking : "",
				type: herd.data.type,
				score: c ? c.data.score : "",
				currentWeight: "",
			},
		};
	};
	const [cows, setCows] = useState([getEmptyCow()]);
	const [loaded, setLoaded] = useState(false);
	const [simple, setSimple] = useState(true);

	const [startTag, setStartTag] = useState("");
	const [head, setHead] = useState("");

	const [hefCnt, setHefCnt] = useState("");
	const [bullCnt, setBullCnt] = useState("");
	const [steerCnt, setSteerCnt] = useState("");

	const [type, setType] = useState(herd.data.type);
	const [status, setStatus] = useState(C.STATUS.OPEN);
	const [error, setError] = useState("");
	const [treatments, setTreatments] = useState([]);
	const [sex, setSex] = useState("Hef");
	const [source, setSource] = useState("");

	useEffect(() => {
		if (loaded) {
			return;
		}

		const tString = localStorage.getItem(STORAGE_KEY_TREATMENT);
		if (tString) {
			const treatmentsExisting = JSON.parse(tString);
			if (treatmentsExisting) {
				setTreatments(treatmentsExisting);
			}
		}

		const cString = localStorage.getItem(STORAGE_KEY);
		if (!cString) {
			return;
		}
		const cowsExisting = JSON.parse(cString);
		if (!cowsExisting) {
			return;
		}
		setCows(cowsExisting);
		setLoaded(true);
	}, [loaded, STORAGE_KEY, STORAGE_KEY_TREATMENT]);

	const updateRow = (idx, key, value) => {
		const cowsCopy = cows.slice();
		cowsCopy[idx].data[key] = value;

		if (cowsCopy[cowsCopy.length - 1].data.tag) {
			cowsCopy.push(getEmptyCow(cowsCopy));
		}
		setCows(cowsCopy);
		localStorage.setItem(STORAGE_KEY, JSON.stringify(cowsCopy));
	};

	const onSave = () => {
		try {
			const toSave = cows.map((c) => {
				return {
					...c,
					data: {
						...c.data,
						newTreatments: treatments,
					},
				};
			});
			addCows(herdID, toSave, history);
			setProcessingList(
				cows.map((c) => {
					return { herdID: herdID, guid: c.data.guid, tag: c.data.tag };
				}),
				history,
				true
			);
			localStorage.setItem(STORAGE_KEY, null);
		} catch (e) {
			console.error(e);
			alert("Could not save, ask support");
		}
	};

	const onCreateBulk = () => {
		const cowList = [];
		const matches = startTag.match(/(\d+)/);
		if (startTag && !matches) {
			setError("Invalid Start Tag");
			return;
		}

		const initialNumber = matches ? matches[0] : "";
		setError("");

		const reg = new RegExp(initialNumber);

		let desired = Number(head);
		const data = {
			Hef: Number(hefCnt),
			Bull: Number(bullCnt),
			Steer: Number(steerCnt),
		};
		if (data.Hef === 0) {
			data[sex] = Number(desired);
		}

		let numberToIncrement = Number(initialNumber);
		Object.keys(data).forEach((k) => {
			let requested = data[k];
			if (requested === 0) {
				return;
			}

			for (let i = 10000; i > 0; i--) {
				const c = getEmptyCow(cowList);
				c.data.tag = startTag ? startTag.replace(reg, numberToIncrement) : "";
				c.data.type = type;
				c.data.status = status;
				c.data.newTreatments = treatments;
				c.data.sex = k;
				c.data.source = source !== "" ? source : null;

				const isErrorTag = herd.tagExists(c.data.tag);
				if (isErrorTag) {
					numberToIncrement += 1;
					continue;
				}

				cowList.push(c);

				/*if (status === C.STATUS.PAIR) {
					const c = getEmptyCow(cowList);
					c.data.tag = startTag.replace(reg, numberToIncrement);
					c.data.type = type;
					c.data.status = status;
				}*/

				requested--;
				numberToIncrement += 1;

				if (requested === 0) {
					break;
				}
			}
		});

		if (cowList.length > 0) {
			addCows(herdID, cowList, history);
			setProcessingList(
				cowList.map((c) => {
					return { herdID: herdID, guid: c.data.guid, tag: c.data.tag };
				}),
				history,
				true
			);
		}
	};

	const onReset = () => {
		localStorage.setItem(STORAGE_KEY, null);
		setCows([getEmptyCow()]);
		setSimple(true);
	};

	const renderTreatments = () => {
		return (
			<div>
				<br />
				<div>
					<div className={"right"}>
						<Popover hasBackdrop={true} minimal={true}>
							<Icon icon={"add"} iconSize={20} />
							<AddTreatment
								property={property}
								addTreatment={(type, name, description) => {
									const newTreatments = [
										...treatments,
										{
											type,
											name,
											description,
										},
									];
									setTreatments(newTreatments);
									localStorage.setItem(STORAGE_KEY_TREATMENT, JSON.stringify(newTreatments));
								}}
							/>
						</Popover>
					</div>
					Treatments to apply to all
				</div>
				<table>
					<tbody>
						{treatments.map((t, idx) => (
							<tr key={`treatment-${idx}`}>
								<td style={{ width: "1%", textAlign: "left" }}>
									<Icon icon={t.type !== "I" ? "shield" : "warning-sign"} />
								</td>
								<td style={{ width: "25%", textAlign: "left" }}>
									<Popover hasBackdrop={true} minimal={true}>
										<div>
											<span>{t.name}</span>
											{t.description && <div>{t.description}</div>}
										</div>
										<div>
											<Button
												intent={Intent.DANGER}
												onClick={() => {
													const clone = treatments.slice();
													clone.splice(idx, 1);
													setTreatments(clone);
													localStorage.setItem(STORAGE_KEY_TREATMENT, JSON.stringify(clone));
												}}
											>
												Remove {t.name}
											</Button>
										</div>
									</Popover>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<br />
			</div>
		);
	};

	if (simple) {
		return (
			<div>
				<div>
					<div>
						<p>
							Tags will be created starting at the tag number and incrementing only the number, avoiding duplicates that
							might already exist in the herd. This is useful when apply new tags to a herd.
							<br />
							Ex: Start tag J122 with Head Count 20 will create tags J122, J123, ... J142.
							<br />
							<br />
							Optionally, you can leave start tag blank and manually add tags during processing.
							<br />
						</p>
						<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
							<FormGroup
								labelFor="source"
								label={"Origin"}
								inline={true}
								style={{ flex: 1, padding: 5, justifyContent: "space-between" }}
							>
								<InputGroup
									className={`test-0`}
									id="source"
									placeholder=""
									value={source}
									onChange={(e) => setSource(e.target.value)}
								/>
							</FormGroup>
						</div>
						<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
							<FormGroup
								labelFor="tag"
								label={"Start Tag (Optional)"}
								inline={true}
								style={{ flex: 1, padding: 5, justifyContent: "space-between" }}
								helperText={error}
							>
								<InputGroup
									className={`test-0`}
									type={"text"}
									id="tag"
									placeholder=""
									intent={error ? Intent.DANGER : null}
									value={startTag}
									onChange={(e) => setStartTag(e.target.value.toUpperCase())}
								/>
							</FormGroup>
						</div>
						{herd.data.type !== "Stocker" && herd.data.type !== "Calf" && (
							<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
								<FormGroup
									labelFor="head"
									label={"Head Count"}
									inline={true}
									style={{ flex: 1, padding: 5, justifyContent: "space-between" }}
								>
									<InputGroup
										className={`test-0`}
										type={"number"}
										id="head"
										placeholder=""
										value={head}
										onChange={(e) => setHead(e.target.value)}
									/>
								</FormGroup>
							</div>
						)}
						{(herd.data.type === "Stocker" || herd.data.type === "Calf") && (
							<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
								<FormGroup
									label={"Hef Count"}
									inline={false}
									style={{ flex: 1, padding: 5, justifyContent: "space-between" }}
								>
									<InputGroup
										className={`test-0`}
										type={"number"}
										placeholder=""
										value={hefCnt}
										onChange={(e) => setHefCnt(e.target.value)}
									/>
								</FormGroup>
								<FormGroup
									label={"Bull Count"}
									inline={false}
									style={{ flex: 1, padding: 5, justifyContent: "space-between" }}
								>
									<InputGroup
										className={`test-0`}
										type={"number"}
										placeholder=""
										value={bullCnt}
										onChange={(e) => setBullCnt(e.target.value)}
									/>
								</FormGroup>
								<FormGroup
									label={"Steer Count"}
									inline={false}
									style={{ flex: 1, padding: 5, justifyContent: "space-between" }}
								>
									<InputGroup
										className={`test-0`}
										type={"number"}
										placeholder=""
										value={steerCnt}
										onChange={(e) => setSteerCnt(e.target.value)}
									/>
								</FormGroup>
							</div>
						)}

						{(type === "Cow" || type === "Calf") && (
							<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
								<FormGroup
									labelFor="status"
									label={"Status"}
									inline={true}
									style={{ flex: 1, padding: 5, justifyContent: "space-between" }}
								>
									<HTMLSelect className={".bp3-fill"} value={status} onChange={(e) => setStatus(e.target.value)}>
										<option value={C.STATUS.OPEN}>Open</option>
										{type === "Cow" && <option value={C.STATUS.PREG}>Preg</option>}
										{(type === "Cow" || type.startsWith("Calf")) && <option value={C.STATUS.PAIR}>Pair</option>}
										{type === "Cow" && <option value={C.STATUS.DRY}>Dry</option>}
									</HTMLSelect>
								</FormGroup>
							</div>
						)}
						{renderTreatments()}
					</div>
				</div>
				<div className={"left"}>
					<Button onClick={() => setSimple(false)}>Enter Manually</Button>
				</div>
				<div className={"right"}>
					<Button intent={Intent.PRIMARY} onClick={onCreateBulk}>
						Start
					</Button>
				</div>
			</div>
		);
	}

	return (
		<div>
			{renderTreatments()}

			{cows.map((r, i) => {
				const isDup = cows.filter((c) => c.data.tag !== "" && c.data.tag === r.data.tag).length > 1;
				return <div key={i}>{displayRow(i, r, updateRow, herd, isDup)}</div>;
			})}
			<br />

			<div className={"left"}>
				<Popover hasBackdrop={true} minimal={true}>
					<Button intent={Intent.WARNING}>Reset</Button>
					<div style={{ padding: 10 }}>
						<p>This can not be undone!</p>
						<Button className={Classes.POPOVER_DISMISS}>Cancel</Button>
						<Button className={Classes.POPOVER_DISMISS} intent={Intent.DANGER} onClick={onReset}>
							Reset
						</Button>
					</div>
				</Popover>
			</div>
			<div className={"right"}>
				<Button intent={Intent.PRIMARY} onClick={onSave}>
					Start
				</Button>
			</div>
		</div>
	);
}

function displayRow(idx, cow, updateValue, herd, isDup) {
	const isErrorTag = isDup || herd.tagExists(cow.data.tag);
	return (
		<div>
			<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
				<FormGroup
					labelFor="tag"
					label={"Tag"}
					inline={true}
					style={{ flex: 1, padding: 5 }}
					helperText={isErrorTag ? "Tag already exists" : ""}
				>
					<InputGroup
						className={`test-${idx}`}
						type={"text"}
						id="tag"
						intent={isErrorTag ? Intent.DANGER : null}
						placeholder=""
						value={cow.data.tag}
						onChange={(e) => updateValue(idx, "tag", e.target.value.toUpperCase())}
					/>
				</FormGroup>
				<FormGroup labelFor="marking" label={"Marking"} inline={true} style={{ flex: 1, padding: 5 }}>
					<InputGroup
						id="marking"
						placeholder="Marking"
						value={cow.data.marking}
						onChange={(e) => updateValue(idx, "marking", e.target.value)}
					/>
				</FormGroup>
				<FormGroup labelFor="type" label={"Type"} inline={true} style={{ flex: 1, padding: 5 }}>
					<HTMLSelect
						className={".bp3-fill"}
						value={cow.data.type}
						onChange={(e) => updateValue(idx, "type", e.target.value)}
					>
						<option value={"Cow"}>Cow</option>
						<option value={"Calf - Hef"}>Calf - Hef</option>
						<option value={"Calf - Bull"}>Calf - Bull</option>
						<option value={"Bull"}>Bull</option>
						<option value={"Stocker"}>Stocker</option>
					</HTMLSelect>
				</FormGroup>
			</div>
			<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
				<FormGroup labelFor="status" label={"Status"} inline={true} style={{ flex: 1, padding: 5 }}>
					<HTMLSelect
						className={".bp3-fill"}
						value={cow.data.status}
						onChange={(e) => updateValue(idx, "status", e.target.value)}
					>
						<option value={C.STATUS.OPEN}>Open</option>
						{cow.data.type === "Cow" && <option value={C.STATUS.PREG}>Preg</option>}
						{(cow.data.type === "Cow" || cow.data.type.startsWith("Calf")) && (
							<option value={C.STATUS.PAIR}>Pair</option>
						)}
						{cow.data.type === "Cow" && <option value={C.STATUS.DRY}>Dry</option>}
					</HTMLSelect>
				</FormGroup>
				<FormGroup labelFor="score" label={"Score"} inline={true} style={{ flex: 1, padding: 5 }}>
					<InputGroup
						type={"number"}
						id="score"
						placeholder="0 - 10"
						value={cow.data.score}
						onChange={(e) => updateValue(idx, "score", e.target.value)}
					/>
				</FormGroup>
				<FormGroup labelFor="weight" label={"Weight"} inline={true} style={{ flex: 1, padding: 5 }}>
					<InputGroup
						type={"number"}
						id="weight"
						placeholder="lbs"
						value={cow.data.currentWeight}
						onChange={(e) => updateValue(idx, "currentWeight", e.target.value)}
					/>
				</FormGroup>
			</div>
			<hr />
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	property: ranchSelectors.property,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			addCows: actions.addCows,
			setProcessingList: actions.setProcessingList,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Receive);
