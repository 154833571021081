import React from "react";
import { FormGroup, InputGroup, Slider } from "@blueprintjs/core";

function ScoreInput({ value, onChange, onRelease }) {
	return (
		<div style={{ marginLeft: 15, width: "calc(100% - 30px)" }}>
			<div>
				<Slider
					min={0}
					max={10}
					stepSize={1}
					labelStepSize={20}
					onChange={onChange}
					onRelease={onRelease}
					value={Number(value)}
					labelRenderer={(number) => {
						if (value === 0) {
							return "0";
						}
						if (number === 0) {
							return "";
						}
						return number;
					}}
				/>
			</div>
		</div>
	);
}

export default ScoreInput;
