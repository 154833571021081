import React, { useState } from "react";
import { Collapse, Icon } from "@blueprintjs/core";

function CollapseDiv({ children }) {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div>
			<div onClick={() => setIsOpen(!isOpen)}>
				<div className={"clear"}>
					<Icon icon={isOpen ? "caret-down" : "caret-right"} style={{ float: "left", padding: "0px 5px" }} />
					{children[0]}
				</div>
			</div>
			<Collapse isOpen={isOpen}>{children[1]}</Collapse>
		</div>
	);
}

export default CollapseDiv;
