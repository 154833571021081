import React, { useState } from "react";
import { Button, FormGroup, HTMLSelect, InputGroup } from "@blueprintjs/core";
import WeightInput from "./WeightInput";
import { Intent } from "@blueprintjs/core/lib/cjs/common/intent";
import * as Classes from "@blueprintjs/core/lib/cjs/common/classes";

function AddCalf({ property, addCalf, replaceCow, inputCow = "" }) {
	const defaultHerd = property.getHerds().find((h) => h.data.name === "Calf");
	let defaultHerdID = "";
	if (defaultHerd) {
		defaultHerdID = defaultHerd.data.ID;
	}
	const [cowTag, setCowTag] = useState(inputCow ? inputCow.data.tag : "");
	const [calfTag, setCalfTag] = useState(inputCow ? inputCow.data.tag : "");
	const [weight, setWeight] = useState("");
	const [marking, setMarking] = useState("");
	const [type, setType] = useState("Calf - Hef");
	const [calfHerdID, setCalfHerdID] = useState(defaultHerdID);

	const isInHerd = (tag, herdID) => {
		const herd = property.getHerd(herdID);
		return herd.tagExists(tag);
	};

	const trySetCalfTag = (tag) => {
		setCalfTag(tag.toUpperCase());
	};

	const trySetCalfHerdID = (herdID) => {
		herdID = Number(herdID);
		setCalfHerdID(herdID);
	};

	const onSave = () => {
		const calf = {
			data: {
				status: "Open",
				tag: calfTag,
				marking: marking,
				type: type,
				currentWeightType: "Birth",
				currentWeight: weight,
			},
		};
		let mother = inputCow;
		if (!mother) {
			// TODO
		}
		addCalf(calfHerdID, calf, mother);
	};

	const isErrorTag = isInHerd(calfTag, calfHerdID);
	return (
		<div style={{ margin: 20, zIndex: 2323 }}>
			{!inputCow && (
				<FormGroup label={"Cow Tag"}>
					<InputGroup type={"text"} value={cowTag} onChange={(e) => setCowTag(e.target.value)} />
				</FormGroup>
			)}
			<FormGroup labelFor="type" label={"Sex"}>
				<HTMLSelect className={".bp3-fill"} value={type} onChange={(e) => setType(e.target.value)}>
					<option value={"Calf - Hef"}>Heifer</option>
					<option value={"Calf - Bull"}>Bull</option>
				</HTMLSelect>
			</FormGroup>
			<FormGroup label={"Marking"}>
				<InputGroup type={"text"} value={marking} onChange={(e) => setMarking(e.target.value)} />
			</FormGroup>
			<FormGroup labelFor="type" label={"Calf Herd"}>
				<HTMLSelect className={".bp3-fill"} value={calfHerdID} onChange={(e) => trySetCalfHerdID(e.target.value)}>
					{property.getHerds().map((h, idx) => (
						<option key={"herd-" + idx} value={h.data.ID}>
							{h.data.name}
						</option>
					))}
				</HTMLSelect>
			</FormGroup>
			<FormGroup label={"Calf Tag"} helperText={isErrorTag ? "Tag already exists" : ""}>
				<InputGroup
					intent={isErrorTag ? Intent.DANGER : null}
					type={"text"}
					value={calfTag}
					onChange={(e) => trySetCalfTag(e.target.value)}
				/>
			</FormGroup>
			<br />
			<br />
			<WeightInput value={weight} onChange={(value) => setWeight(value)} />

			<div className={"right"}>
				<Button className={Classes.POPOVER_DISMISS}>Cancel</Button>
				<Button className={Classes.POPOVER_DISMISS} intent={Intent.PRIMARY} disabled={isErrorTag} onClick={onSave}>
					Save
				</Button>
			</div>
			<div className={"clear"} />
			<br />
			<br />
		</div>
	);
}

export default AddCalf;
