import React, { useEffect, useState } from "react";

import Popover from "../components/Popover";
import { Button, HTMLSelect, InputGroup, Intent } from "@blueprintjs/core";
import { bindActionCreators } from "redux";
import { actions, selectors as ranchSelectors } from "./redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";

import "./index.scss";
import * as Classes from "@blueprintjs/core/lib/cjs/common/classes";
import C from "../Constants";

function BulkStatus({ property, herdID, replaceCows, processingList, setProcessingList }) {
	const herd = property.getHerd(herdID);

	const cowsList = herd.getCows();
	const history = useHistory();
	const STORAGE_KEY = `bulkStatusTemp_${herdID}`;
	const defaultCows = cowsList
		.filter((c) => processingList.find((pl) => pl.guid === c.data.guid))
		.map((c) => {
			return { ...c, data: { ...c.data, currentWeight: "", currentWeightType: "Other" } };
		});
	const [cows, setCows] = useState(defaultCows);
	const [loaded, setLoaded] = useState(false);

	const [newStatus, setNewStatus] = useState("Sold");

	useEffect(() => {
		if (loaded) {
			return;
		}
		const cString = localStorage.getItem(STORAGE_KEY);
		if (!cString) {
			return;
		}
		const cowsExisting = JSON.parse(cString);
		if (!cowsExisting) {
			return;
		}
		setCows(cowsExisting);
		setLoaded(true);
	}, [loaded, STORAGE_KEY]);

	const updateRow = (idx, key, value) => {
		const cowsCopy = cows.slice();
		if (key === "selected") {
			cowsCopy[idx][key] = value;
		} else {
			cowsCopy[idx].data[key] = value;
		}

		setCows(cowsCopy);
		localStorage.setItem(STORAGE_KEY, JSON.stringify(cowsCopy));
	};

	const onSave = () => {
		const toSave = cows.map((c) => {
			return {
				...c,
				data: {
					...c.data,
					status: newStatus,
					inactive: newStatus === C.STATUS.DEATH || newStatus === C.STATUS.SOLD,
				},
			};
		});
		try {
			setProcessingList(null, null, false);

			replaceCows(toSave);
			localStorage.setItem(STORAGE_KEY, null);
			history.push(`/herd/${herd.data.ID}`);
		} catch (e) {
			console.error(e);
			alert("Could not save, ask support");
		}
	};

	const onReset = () => {
		localStorage.setItem(STORAGE_KEY, null);
		setCows(defaultCows);
	};

	return (
		<div>
			<div className={"right"}>
				<Button intent={Intent.PRIMARY} onClick={onSave}>
					Save
				</Button>
			</div>
			<h3>Status to Apply</h3>
			<div className={"clear"}></div>
			<HTMLSelect className={".bp3-fill"} value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
				<option value={C.STATUS.SOLD}>Sold</option>
				<option value={C.STATUS.DRY}>Dry</option>

				<option value={C.STATUS.OPEN}>Open</option>
				<option value={C.STATUS.PREG}>Preg</option>
				<option value={C.STATUS.PAIR}>Pair</option>
			</HTMLSelect>

			<br />
			<div style={{ position: "relative", top: 15 }} className={"right"}>
				{cows.reduce((acc, c) => (c.selected ? acc + 1 : acc), 0)} Selected
			</div>

			<br />
			{cows.map((r, i) => {
				return <div key={i}>{displayRow(i, r, updateRow)}</div>;
			})}
			<br />
			<div className={"left"}>
				<Popover hasBackdrop={true} minimal={true}>
					<Button intent={Intent.WARNING}>Reset</Button>
					<div style={{ padding: 10 }}>
						<p>This can not be undone!</p>
						<Button className={Classes.POPOVER_DISMISS}>Cancel</Button>
						<Button className={Classes.POPOVER_DISMISS} intent={Intent.DANGER} onClick={onReset}>
							Reset
						</Button>
					</div>
				</Popover>
			</div>
			<div className={"right"}>
				<Button intent={Intent.PRIMARY} onClick={onSave}>
					Save
				</Button>
			</div>
		</div>
	);
}

function displayRow(idx, cow, updateValue) {
	return (
		<div>
			<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "baseline" }}>
				<div style={{ width: 140, flex: 1 }}>
					{cow.data.tag} - {cow.data.status}
				</div>
				<div style={{ paddingLeft: 20, flex: 1 }}>
					<InputGroup
						placeholder={"Weight"}
						value={cow.data.currentWeight}
						onChange={(e) => updateValue(idx, "currentWeight", e.target.value)}
					/>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	property: ranchSelectors.property,
	processingList: ranchSelectors.processingList,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			replaceCows: actions.replaceCows,
			setProcessingList: actions.setProcessingList,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(BulkStatus);
