import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { selectors as ranchSelectors, actions } from "../redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Button, Icon, Intent } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";

function ProcessingTable({ processingList }) {
	const history = useHistory();

	const [tab, setTab] = useState("pending");
	const [page, setPage] = useState(0);

	const start = page * 10;

	const pending = processingList.filter((p) => !p.seen);
	const completed = processingList.filter((p) => p.seen);

	const jumpToTop = () => {
		document.scrollingElement.scrollTop = 0;
	};

	const changeTab = (tab) => {
		setTab(tab);
		setPage(0);
	};

	return (
		<div>
			<br />
			<h2>Processing List</h2>

			<div className={"flex"}>
				<Button
					style={{ flexGrow: 1, borderRadius: "8px 8px 0 0" }}
					icon={tab === "pending" ? <Icon icon={"tick"} iconSize={10} /> : null}
					intent={tab === "pending" ? Intent.PRIMARY : Intent.NONE}
					onClick={() => changeTab("pending")}
				>
					Pending
				</Button>
				<Button
					style={{ flexGrow: 1, borderRadius: "8px 8px 0 0" }}
					icon={tab === "completed" ? <Icon icon={"tick"} iconSize={10} /> : null}
					intent={tab === "completed" ? Intent.PRIMARY : Intent.NONE}
					onClick={() => changeTab("completed")}
				>
					Completed
				</Button>
			</div>
			{tab === "pending" && (
				<div>
					<div>
						{pending.slice(start, start + 10).map((p, idx) => (
							<NavLink key={"pending" + idx} to={`/herd/${p.herdID}/cow/${p.guid}`} onClick={jumpToTop}>
								<div style={{ padding: 5 }}>{p.tag ? p.tag : <i>Untagged</i>}</div>
							</NavLink>
						))}
					</div>

					<br />
					<div className={"right"}>
						<Button icon={"caret-left"} onClick={() => setPage(page - 1)} disabled={page === 0} />
						<Button icon={"caret-right"} onClick={() => setPage(page + 1)} disabled={start + 10 >= pending.length} />
					</div>
				</div>
			)}

			{tab === "completed" && (
				<div>
					<div>
						{completed.slice(start, start + 10).map((p, idx) => (
							<NavLink key={"completed" + idx} to={`/herd/${p.herdID}/cow/${p.guid}`}>
								<div style={{ padding: 5 }}>{p.tag}</div>
							</NavLink>
						))}
					</div>

					<br />
					<div className={"right"}>
						<Button icon={"caret-left"} onClick={() => setPage(page - 1)} disabled={page === 0} />
						<Button icon={"caret-right"} onClick={() => setPage(page + 1)} disabled={start + 10 >= completed.length} />
					</div>
				</div>
			)}
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	property: ranchSelectors.property,
	processingList: ranchSelectors.processingList,
	processingIdx: ranchSelectors.processingIdx,
	processingConfig: ranchSelectors.processingConfig,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			changeProcessingIdx: actions.changeProcessingIdx,
			setProcessingList: actions.setProcessingList,
			clearProcessingList: actions.clearProcessingList,
			removeBlankFromHerd: actions.removeBlankFromHerd,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingTable);
