import React from "react";
import Chart from "react-google-charts";

function Summary({ title, total, dataSets }) {
	return (
		<div className={"Summary"}>
			<div style={{ fontSize: 12, fontWeight: 600 }}>
				<div className={"right"}>
					<b>{total} Total</b>
				</div>
				{title ? title : "Livestock Summary"}
				<div className={"clear"}></div>
			</div>
			{dataSets.map((d, i) => (
				<Chart
					key={i}
					chartType={"ColumnChart"}
					options={{
						legend: "none",
						is3D: true,
						colors: ["#b15f0a"],
					}}
					width={"100%"}
					height={150}
					data={d}
				/>
			))}
		</div>
	);
}

export default Summary;
