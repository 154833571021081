import React, { useEffect, useState } from "react";

import Popover from "../components/Popover";
import { Button, FormGroup, HTMLSelect, InputGroup, Intent, Spinner } from "@blueprintjs/core";
import { bindActionCreators } from "redux";
import { actions, selectors as ranchSelectors } from "./redux";
import { connect } from "react-redux";
import Page from "../components/Page";
import { createStructuredSelector } from "reselect";
import { NavLink, useHistory } from "react-router-dom";

import "./index.scss";
import * as Classes from "@blueprintjs/core/lib/cjs/common/classes";
import Search from "./Search";
import Summary from "./components/Summary";

import { useSwipeable, Swipeable } from "react-swipeable";
import HerdManage from "./components/HerdManage";

function PropertyManage({ property, match, createProperty, removeHerd, removeProperty, updatePropertyName }) {
	const [propertyName, setPropertyName] = useState(property.data.name);

	const history = useHistory();

	const onRemoveProperty = () => {
		removeProperty(property, history);
	};

	const saveProperty = () => {
		//createProperty(property.data.name, herdName, herdType);
	};

	const onRemoveHerd = (herd) => {
		removeHerd(herd, history);
	};

	const herds = property.getHerds();

	return (
		<Page
			header={`${property.data.name}`}
			className={"Property"}
			backLink={herds.length === 0 ? "/" : `/property/${property.data.ID}`}
		>
			<div>
				<h2>Manage Property</h2>
				<div>
					<FormGroup labelFor="propertyName" label={"Property Name"}>
						<div style={{ display: "flex" }}>
							<InputGroup id="propertyName" value={propertyName} onChange={(e) => setPropertyName(e.target.value)} />{" "}
							<Button
								disabled={property.data.name === propertyName}
								onClick={() => {
									updatePropertyName(property, propertyName);
								}}
							>
								Save
							</Button>
						</div>
					</FormGroup>
					<br />
					<br />
				</div>
				<h3>Herds</h3>
				<div style={{ padding: 5 }}>
					{herds.map((herd) => (
						<div key={`property-${herd.data.ID}`} className={"property-item "}>
							<div className={"title no-border"}>
								<div className={"right"}>
									<Popover hasBackdrop={true} minimal={true}>
										<Button
											icon={"trash"}
											minimal={true}
											style={{ position: "relative", top: -7 }}
											intent={Intent.WARNING}
										>
											Delete
										</Button>
										<div style={{ padding: 20 }}>
											<p>
												This will <b>remove all cattle</b> inside this herd.
											</p>
											<Button className={Classes.POPOVER_DISMISS}>Cancel</Button>
											<Button intent={Intent.DANGER} onClick={() => onRemoveHerd(herd)} icon={"trash"}>
												Delete Permanently
											</Button>
										</div>
									</Popover>

									<Popover hasBackdrop={true} minimal={true} propToMonitor={herd}>
										<Button
											icon={"cog"}
											minimal={true}
											style={{ position: "relative", top: -7 }}
											intent={Intent.WARNING}
										>
											Manage
										</Button>

										<HerdManage herd={herd} />
									</Popover>
								</div>
								<div>{herd.data.name}</div>
							</div>
						</div>
					))}
					<br />
					<div style={{ float: "right" }}>
						<Popover hasBackdrop={true} minimal={true} position={"bottom"} propToMonitor={property}>
							<Button intent={Intent.PRIMARY}>New Herd</Button>
							<HerdManage />
						</Popover>
					</div>
					<Popover hasBackdrop={true} minimal={true}>
						<Button icon={"trash"} intent={Intent.WARNING}>
							Delete Property
						</Button>
						<div style={{ padding: 20 }}>
							<p>
								This will <b>remove all herds</b> and <b>remove all cattle</b> inside these herds.
							</p>
							<Button className={Classes.POPOVER_DISMISS}>Cancel</Button>
							<Button intent={Intent.DANGER} onClick={onRemoveProperty} icon={"trash"}>
								Delete Permanently
							</Button>
						</div>
					</Popover>
				</div>
			</div>
		</Page>
	);
}

const mapStateToProps = createStructuredSelector({
	property: ranchSelectors.property,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			removeHerd: actions.removeHerd,
			createProperty: actions.createProperty,
			removeProperty: actions.removeProperty,
			updatePropertyName: actions.updatePropertyName,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(PropertyManage);
