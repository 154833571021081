import Cow from "./Cow";
import { convertToClass } from "./utils";
import moment from "moment";
import { ParseBool } from "../util";

class Herd {
	constructor(data = {}) {
		this.PRIMARY_KEYS = ["ID", "propertyID"];
		this.data = {
			ID: 0,
			name: "",
			propertyID: 0,
			cows: [],
			feedHistory: [],
			...data,
		};

		if (data.cows) {
			this.data.cows = data.cows.map((cow) => convertToClass(cow, Cow));
		}
	}

	getCows() {
		return this.data.cows
			.slice()
			.filter((c) => c && !ParseBool(c.data.inactive) && Number(c.data.herdID) === Number(this.data.ID));
	}

	countCows() {
		return this.getCows().length;
	}

	getCow(tag) {
		return this.data.cows.find(
			(c) => c && c.data.tag && !ParseBool(c.data.inactive) && c.data.tag.toLowerCase() === tag.toLowerCase()
		);
	}

	// Find by ID, if not find by tag.
	findCow(cowID) {
		const cow = this.getCows().find((cow) => {
			if (cow.data.guid === cowID) {
				return true;
			}
			if (cow.data.tag === cowID) {
				return true;
			}
			return false;
		});

		if (!cow) {
			return [null, null];
		}

		return [cow, convertToClass({ data: { ...cow.data } }, Cow)];
	}

	findPrevCowGuid(cowID) {
		let cowIdx = this.data.cows.findIndex((cow) => {
			if (!cow) {
				return false;
			}
			if (cow.data.guid === cowID) {
				return true;
			}
			if (cow.data.tag === cowID) {
				return true;
			}
			return false;
		});

		cowIdx = cowIdx - 1;
		if (cowIdx < 0 || !this.data.cows[cowIdx]) {
			return null;
		}

		return this.data.cows[cowIdx].data.guid;
	}

	findNextCowGuid(cowID) {
		let cowIdx = this.data.cows.findIndex((cow) => {
			if (!cow) {
				return false;
			}
			if (cow.data.guid === cowID) {
				return true;
			}
			if (cow.data.tag === cowID) {
				return true;
			}
			return false;
		});

		if (cowIdx === -1) {
			return null;
		}

		cowIdx = cowIdx + 1;
		if (cowIdx > this.data.cows.length || !this.data.cows[cowIdx]) {
			return null;
		}

		return this.data.cows[cowIdx].data.guid;
	}

	tagExists(tag) {
		return this.getCow(tag) !== undefined;
	}

	checkAndUpdateWeightProp(cow, createdBy) {
		if (!cow.data.currentWeight) {
			return cow;
		}
		cow.addWeight(
			cow.data.currentWeightType ? cow.data.currentWeightType : "Received",
			cow.data.currentWeight,
			createdBy
		);
		delete cow.data.currentWeight;
		delete cow.data.currentWeightType;

		return cow;
	}

	checkNewTreatmentsProp(cow, createdBy) {
		if (!cow.data.newTreatments) {
			return cow;
		}

		cow.data.newTreatments.forEach((t) => {
			cow.addTreatment(t.type, t.name, t.description, createdBy);
		});
		delete cow.data.newTreatments;
		return cow;
	}

	addCow(cow, createdBy) {
		if (!cow.data.herdID) {
			cow.data.herdID = this.data.ID;
		}
		if (!cow.data.guid) {
			cow.data.guid = this.newGuid();
		}
		cow.data.inactive = false;
		cow = this.checkAndUpdateWeightProp(cow, createdBy);
		cow = this.checkNewTreatmentsProp(cow, createdBy);
		this.data.cows.push(Object.assign({}, cow));
	}

	newGuid() {
		let dt = new Date().getTime();
		const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			var r = (dt + Math.random() * 16) % 16 | 0;
			dt = Math.floor(dt / 16);
			return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
		});
		return uuid;
	}

	replaceCow(cow, createdBy, trackHistory = false) {
		const oldIndex = this.data.cows.findIndex((c) => c && c.data.guid === cow.data.guid);
		if (oldIndex === -1) {
			return;
		}
		cow = this.checkAndUpdateWeightProp(cow, createdBy);
		cow = this.checkNewTreatmentsProp(cow, createdBy);

		const oldCow = this.data.cows[oldIndex];
		const getStatusRow = (label, field) => {
			if (oldCow.data[field] === cow.data[field]) {
				return null;
			}
			cow.data.statusHistory.push({
				date: moment().format("YYYY-MM-DD"),
				field: label,
				status: cow.data[field],
				createdBy: createdBy,
			});
		};

		if (trackHistory) {
			getStatusRow("Status", "status");
			getStatusRow("Type", "type");
			getStatusRow("Score", "score");
			getStatusRow("Marking", "marking");
		}

		this.data.cows[oldIndex] = convertToClass(cow, Cow);
	}

	removeCow(cow) {
		const oldIndex = this.data.cows.findIndex((c) => c && c.data.guid === cow.data.guid);
		if (oldIndex === -1) {
			return;
		}
		this.data.cows[oldIndex].data.inactive = true;
	}

	deleteCow(cow) {
		const oldIndex = this.data.cows.findIndex((c) => c && c.data.guid === cow.data.guid);
		if (oldIndex === -1) {
			return;
		}
		this.data.cows[oldIndex].data.inactive = true;
		this.data.cows[oldIndex].skipSave = true; // Find a better way.  Store is looking for a skipSave prop.
	}

	getFeedHistory() {
		return this.data.feedHistory;
	}

	addFeedHistory(feedHistory) {
		this.data.feedHistory.push(Object.assign({}, feedHistory));
	}

	setName(name) {
		this.data.name = name;
	}

	setLot(lot) {
		this.data.lot = lot;
	}

	getSummary(propertyName = null) {
		const typeResults = {};
		const herd = this;

		let key = herd.data.name;

		if (!herd.data.cows || herd.data.cows.length === 0) {
			typeResults[key] = {
				total: Number(herd.data.count),
				type: herd.data.type,
				propertyName: propertyName,
				weight: 0,
			};
			return typeResults;
		}
		herd.data.cows.forEach((c) => {
			if (ParseBool(c.data.inactive)) {
				return;
			}

			if (!typeResults[key]) {
				typeResults[key] = {
					total: 0,
					weight: 0,
				};
			}
			typeResults[key].total += 1;
			const foundWt = c.data.weight[c.data.weight.length - 1];
			if (foundWt) {
				typeResults[key].weight += Number(foundWt.weight);
			}
		});
		return typeResults;
	}
}
export default Herd;
