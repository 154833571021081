import { Label, Icon } from "@blueprintjs/core";
import React, { useState } from "react";

function CameraCapture({ setValue, setMessage, position, actionIcon }) {
	const [camera, hasCamera] = useState(false);

	const playerID = "player-" + Math.random();

	if (!setMessage) {
		setMessage = () => {};
	}

	const onSearchChange = (value) => {
		setValue(value);
	};

	const getVisionResult = async (b64) => {
		const response = await fetch(
			"https://vision.googleapis.com/v1/images:annotate?key=AIzaSyBp0OwGqIq7gu0lmk02AqzjZpreXOocWIU",
			{
				method: "POST",
				mode: "cors",
				cache: "no-cache",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					requests: [
						{
							image: {
								content: b64,
							},
							features: [
								{
									type: "TEXT_DETECTION",
								},
							],
						},
					],
				}),
			}
		);

		if (response.status !== 200) {
			setMessage("Request failed.");
			return null;
		}

		const json = await response.json();
		const r = json.responses[0];
		if (!r || !r.textAnnotations || !r.textAnnotations.length === 0) {
			setMessage("Text not found - No text found");
			return null;
		}
		return r;
	};

	const handleFileImage = (b64) => {
		setMessage("");
		getVisionResult(b64)
			.then((r) => {
				if (!r) {
					return;
				}

				const filterKnownIssue = (input) => {
					input = input.replace("L1", "4");
					return input;
				};

				onSearchChange(r.textAnnotations.map((ta) => filterKnownIssue(ta.description)));
			})
			.catch((err) => {
				console.log(err);
				setMessage("failed" + err);
			});
	};

	const constraints = {
		video: {
			facingMode: "environment",
			width: 300,
			height: 300,
		},
		audio: false,
	};

	const processVideo = () => {
		const player = document.getElementById(playerID);
		if (!player.isConnected) {
			player.play();
			return;
		}
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");

		context.drawImage(player, 0, 0, 25, 25);
		player.srcObject.getVideoTracks().forEach((track) => track.stop());

		const dataurl = canvas.toDataURL("image/jpeg");
		handleFileImage(dataurl.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""));
		hasCamera(false);
	};

	const onCamClick = () => {
		hasCamera(true);
		const player = document.getElementById(playerID);

		player.setAttribute("autoplay", "");
		player.setAttribute("muted", "");
		player.setAttribute("playsinline", "");

		navigator.mediaDevices
			.getUserMedia(constraints)
			.then((stream) => {
				console.log("Video start");
				player.srcObject = stream;
				player.play();
			})
			.catch((err) => {
				console.log("Video error");
			});
	};

	return (
		<div>
			<div>
				{actionIcon && <div onClick={onCamClick}>{actionIcon}</div>}
				{!actionIcon && (
					<Label onClick={onCamClick} style={{ marginTop: -10, padding: 10, paddingBottom: 0 }}>
						<Icon icon={"camera"} />
					</Label>
				)}
			</div>

			<div
				style={{
					width: "100%",
					textAlign: "center",
					position: position ? position : "fixed",
					margin: "0 auto",
					display: camera ? "" : "none",
					left: 0,
					zIndex: 9999,
				}}
			>
				<video
					onClick={() => processVideo()}
					id={playerID}
					style={{ width: 300, height: 300, background: "black" }}
					controls={false}
					autoPlay
					playsInline
					loop
				></video>
				<div
					onClick={() => processVideo()}
					style={{
						position: "absolute",
						width: 50,
						height: 50,
						bottom: 10,
						left: "calc(50% - 25px)",
						borderRadius: "50%",
						background: "#ccc",
						border: "1px solid gray",
					}}
				></div>
			</div>
		</div>
	);
}

export default CameraCapture;
