import React, { useEffect, useState } from "react";
import { HTMLSelect, InputGroup } from "@blueprintjs/core";

function AddableInput({ value, onChange, options = [] }) {
	const [isNewVal, setIsNewVal] = useState(false);
	const [newVal, setNewVal] = useState(value);

	useEffect(() => {
		setIsNewVal(false);
		setNewVal(value);
	}, [value]);

	const onSelectValueChange = (val) => {
		if (val === "-1") {
			setIsNewVal(true);
			return;
		}
		setIsNewVal(false);
		onChange(val);
	};

	const onInputValueChange = (val) => {
		setNewVal(val);
	};

	if (!isNewVal && options.length > 0) {
		return (
			<HTMLSelect
				style={{ width: "100%" }}
				className={".bp3-fill"}
				value={value}
				onChange={(e) => onSelectValueChange(e.target.value)}
			>
				<option value={""}></option>
				{options.map((opt) => (
					<option key={opt} value={opt}>
						{opt}
					</option>
				))}
				<option value={-1}>Add New</option>
			</HTMLSelect>
		);
	}
	return (
		<InputGroup value={newVal} onBlur={() => onChange(newVal)} onChange={(e) => onInputValueChange(e.target.value)} />
	);
}

export default AddableInput;
