import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { actions, selectors as ranchSelectors } from "./redux";
import { connect } from "react-redux";
import Page from "../components/Page";
import { createStructuredSelector } from "reselect";
import { useSwipeable, Swipeable } from "react-swipeable";

import "./index.scss";
import { Button, FormGroup, HTMLSelect, Icon, InputGroup, Intent, Slider } from "@blueprintjs/core";
import WeightSelect from "./components/WeightSelect";
import WeightInput from "./components/WeightInput";
import AddableInput from "./components/AddableInput";
import { useHistory } from "react-router-dom";
import * as Classes from "@blueprintjs/core/lib/cjs/common/classes";
import AddCalf from "./components/AddCalf";
import C from "../Constants";
import AddTreatment from "./components/AddTreatment";
import Search from "./Search";
import { ParseBool } from "../util";
import ProcessingNav from "./components/ProcessingNav";
import { Position } from "@blueprintjs/core/lib/cjs/common/position";
import ScoreInput from "./components/ScoreInput";
import ChangeTag from "./components/ChangeTag";

import Popover from "../components/Popover";
import ProcessingTable from "./components/ProcessingTable";
import { convertToClass } from "../api/utils";

function Cow({
	property,
	processingList,
	processingIdx,
	changeProcessingIdx,
	match,
	replaceCow,
	removeCow,
	addWeight,
	addScore,
	addTreatment,
	addCalf,
	setFinalStatus,
	removeWeight,
	removeTreatment,
	changeHerd,
}) {
	const cowID = match.params.cowID;
	const herdID = Number(match.params.herdID);
	const herd = property.getHerd(Number(herdID));

	const [cowObj, cow] = herd.findCow(cowID);

	const herds = property.getHerds();
	const cullHerd = herds.find((h) => h.data.name === "Cull");

	let defaultWt = "Other";
	if (cow.data.type.startsWith("Calf")) {
		if (!cow.data.weight || !cow.data.weight.find((w) => w.type === "Birth")) {
			defaultWt = "Birth";
		} else {
			defaultWt = "Ween";
		}
	}

	if (!cow.data.source) {
		cow.data.source = "";
	}
	if (!cow.data.age) {
		cow.data.age = "";
	}
	if (!cow.data.score) {
		cow.data.score = "";
	}
	if (!cow.data.temper) {
		cow.data.temper = "";
	}

	const [weightType, setWeightType] = useState(defaultWt);
	const [weight, setWeight] = useState("");

	const defaultScore =
		cow && cow.data && cow.data.scores && cow.data.scores.length > 0
			? Number(cow.data.scores[cow.data.scores.length - 1].score)
			: 0;

	const [score, setScore] = useState(defaultScore);

	const [tab, setTab] = useState("treatments");
	const [newHerd, setNewHerd] = useState(cullHerd ? cullHerd.data.ID : herdID);

	// Processing
	const [weightAdded, setWeightAdded] = useState(false);
	const [scoreAdded, setScoreAdded] = useState(false);
	const [tempAge, setTempAge] = useState(Number(cow.data.age) || 1);
	const [tempTemper, setTempTemper] = useState(Number(cow.data.temper) || 1);
	const [scoreClicked, setScoreClicked] = useState(false);

	let searchableCows = herd.getCows();
	if (processingList && processingList.length > 0) {
		searchableCows = searchableCows.filter((c) => processingList.find((pl) => pl.guid === c.data.guid));
	}

	useEffect(() => {
		setScore(defaultScore);
		setTab("treatments");
		setTempAge(Number(cow.data.age) || 1);
		setTempTemper(Number(cow.data.temper) || 1);
		setScoreClicked(false);
	}, [cowID]);

	const history = useHistory();
	const updateValue = (key, value) => {
		cow.data[key] = value;
		replaceCow(cow, true);
	};

	const onRemoveCow = () => {
		removeCow(cow, history);
	};

	const onSetFinalStatus = (status) => {
		setFinalStatus(cow, status, history);
	};

	const saveScore = () => {
		if (score === "" || !Number(score) || Number(score) <= 0) {
			return;
		}
		addScore(cow, score);
		setScoreAdded(true);
	};

	const hasScore = cow.data.type !== "Stocker" && !cow.data.type.startsWith("Calf");

	const getOptions = () => {
		return (
			<Popover hasBackdrop={true} minimal={true} targetProps={{ style: { height: "100%", marginTop: 10 } }}>
				<Button style={{ width: "100%", height: "100%", maxHeight: 150 }} icon={"add-to-artifact"} />
				<div style={{ padding: 20, display: "flex", flexDirection: "column" }}>
					<Popover propToMonitor={cow.data.weight.length}>
						<Button style={{ width: 150, justifyContent: "flex-start" }} icon={"add"}>
							Weight
						</Button>
						<div style={{ padding: "20px" }}>
							<WeightSelect value={weightType} type={cow.data.type} onChange={(e) => setWeightType(e.target.value)} />
							<WeightInput value={weight} onChange={(value) => setWeight(value)} />
							<div className={"right"}>
								<Button
									className={Classes.POPOVER_DISMISS}
									disabled={weight === "" || !Number(weight) || Number(weight) <= 0}
									intent={Intent.PRIMARY}
									onClick={() => {
										if (weight === "" || !Number(weight) || Number(weight) <= 0) {
											return;
										}
										addWeight(cow, weightType, weight);
										setWeightAdded(true);
										setWeight("");
									}}
								>
									Save
								</Button>
							</div>
							<br />
							<br />
						</div>
					</Popover>
					{hasScore && (
						<Popover hasBackdrop={true} minimal={true} propToMonitor={cow.data.scores.length}>
							<Button style={{ width: 150, justifyContent: "flex-start" }} icon={"take-action"}>
								Score
							</Button>

							<div style={{ padding: "20px" }}>
								<FormGroup labelFor="score" label={"Score"} style={{ width: "100%" }}>
									<div style={{ padding: "0" }}>
										<ScoreInput onChange={(number) => setScore(number)} value={Number(score)} />
									</div>
								</FormGroup>
								<div className={"right"}>
									<Button
										className={Classes.POPOVER_DISMISS}
										disabled={score === "" || !Number(score) || Number(score) <= 0}
										intent={Intent.PRIMARY}
										onClick={() => {
											saveScore();
										}}
									>
										Save
									</Button>
									<br />
									<br />
								</div>
								<br />
								<br />
							</div>
						</Popover>
					)}
					<Popover hasBackdrop={true} minimal={true} propToMonitor={cow.data.treatments.length}>
						<Button style={{ width: 150, justifyContent: "flex-start" }} icon={"symbol-cross"}>
							Treatment
						</Button>
						<AddTreatment
							property={property}
							addTreatment={(treatmentType, treatment, treatmentDesc) =>
								addTreatment(cow, treatmentType, treatment, treatmentDesc)
							}
						/>
					</Popover>

					<Popover hasBackdrop={true} minimal={true}>
						<Button style={{ width: 150, justifyContent: "flex-start" }} icon={"cross"}>
							Death
						</Button>
						<div style={{ padding: 10 }}>
							<p>This can not be undone!</p>
							<Button className={Classes.POPOVER_DISMISS}>Cancel</Button>
							<Button intent={Intent.DANGER} onClick={() => onSetFinalStatus(C.STATUS.DEATH)}>
								Confirm
							</Button>
						</div>
					</Popover>

					<Popover hasBackdrop={true} minimal={true}>
						<Button style={{ width: 150, justifyContent: "flex-start" }} icon={"dollar"}>
							Sell
						</Button>
						<div style={{ padding: 10 }}>
							<p>This can not be undone!</p>
							<Button className={Classes.POPOVER_DISMISS}>Cancel</Button>
							<Button intent={Intent.DANGER} onClick={() => onSetFinalStatus(C.STATUS.SOLD)}>
								Confirm
							</Button>
						</div>
					</Popover>

					<Popover hasBackdrop={true} minimal={true}>
						<Button style={{ width: 150, justifyContent: "flex-start" }} icon={"merge-columns"}>
							Change Herd
						</Button>
						<div style={{ padding: "20px" }}>
							<FormGroup label={"New Herd"} inline={true}>
								<HTMLSelect className={".bp3-fill"} value={newHerd} onChange={(e) => setNewHerd(e.target.value)}>
									{property.getHerds().map((h) => (
										<option key={"h-" + h.data.ID} value={h.data.ID}>
											{h.data.name}
										</option>
									))}
								</HTMLSelect>
							</FormGroup>
							<div className={"right"}>
								<Button className={Classes.POPOVER_DISMISS}>Cancel</Button>
								<Button
									intent={Intent.PRIMARY}
									onClick={() => changeHerd(cow, newHerd, history)}
									className={Classes.POPOVER_DISMISS}
								>
									Save
								</Button>
							</div>
							<br />
							<br />
						</div>
					</Popover>
				</div>
			</Popover>
		);
	};
	return (
		<Page
			header={`${cow.data.tag} ${cow.data.type} - ${property.data.name} ${herd.data.name}`}
			className={"Cow"}
			backLink={`/herd/${herd.data.ID}`}
		>
			<Search cows={searchableCows} hideCalf={processingList && processingList.length > 0} />
			{processingList && <ProcessingNav weightAdded={weightAdded} scoreAdded={scoreAdded} tag={cow.data.tag} />}

			{/*<Swipeable
				style={{ position: "relative", left: swipePct + "%" }}
				delta={150}
				onSwipedRight={(eventData) => {
					if (eventData.event.srcElement.classList.contains("bp3-slider-handle")) {
						return;
					}

					if (eventData.deltaX > -50) {
						setSwipePct(0);
						return;
					}
					if (processingList) {
						const prev = processingIdx - 1;
						if (prev >= 0) {
							changeProcessingIdx(prev, history);
						}
						setSwipePct(0);
						return;
					}
					const prevCowGuid = herd.findPrevCowGuid(cowID);
					if (prevCowGuid) {
						history.push(`/herd/${herd.data.ID}/cow/${prevCowGuid}`);
					}
					setSwipePct(0);
				}}
				onSwipedLeft={(eventData) => {
					if (eventData.event.srcElement.classList.contains("bp3-slider-handle")) {
						return;
					}

					if (eventData.deltaX < 50) {
						setSwipePct(0);
						return;
					}
					if (processingList) {
						const next = processingIdx + 1;
						if (next < processingList.length) {
							changeProcessingIdx(next, history);
						}
						setSwipePct(0);
						return;
					}
					const nextCowGuid = herd.findNextCowGuid(cowID);
					if (nextCowGuid) {
						history.push(`/herd/${herd.data.ID}/cow/${nextCowGuid}`);
					}
					setSwipePct(0);
				}}
				onSwiping={(eventData) => {
					if (eventData.event.srcElement.classList.contains("bp3-slider-handle")) {
						return;
					}

					const pct = (-1 * eventData.deltaX) / 5;
					if (pct > swipePct + 5 || pct < swipePct + 5) {
						setSwipePct(pct);
						return;
					}
				}}
			>*/}
			<div style={{ display: "flex" }}>
				<div style={{ width: "60%" }}>
					<div style={{ minHeight: 127 }}>
						<Popover id={"tag"} propToMonitor={cow.data.tag} defaultIsOpen={cow.data.tag === ""}>
							<svg
								version="1.0"
								xmlns="http://www.w3.org/2000/svg"
								style={{ width: "90%", height: "auto" }}
								viewBox="0 0 100 100"
							>
								<path
									d="

										M 10 100
										C 5 100, 1 95, 1 90

										L 1 50
										C 1 45, 5 40, 10 40

										L 10 40
										C 20 40, 40 30, 40 20

										C 40 0, 60 0, 60 20

										L 60 20
										C 60 30, 80 40, 90 40

										L 90 40
										C 95 40, 100 45, 99 50

										L 99 90
										C 99 95, 95 99, 90 99

										z
									"
									stroke={"#563a1d"}
									strokeWidth="1px"
									fill={herd.data.color ? herd.data.color : "#ffe135"}
								/>

								<ellipse cx="50" cy="15" rx="5" ry="5" style={{ fill: "#F3F3F3", stroke: "#563A1D", strokeWidth: 1 }} />
								<text x="50%" y="70%" dominantBaseline="middle" textAnchor="middle" fontSize="3em">
									{cow.data.tag}
								</text>
								<text
									fill="#563a1d"
									x="5%"
									y="90%"
									fontSize={"0.7em"}
									dominantBaseline="middle"
									textAnchor="start"
									lengthAdjust="spacingAndGlyphs"
								>
									{cow.data.marking}
								</text>
								<text
									fill="#563a1d"
									x="95%"
									y="90%"
									fontSize={"0.7em"}
									dominantBaseline="middle"
									textAnchor="end"
									lengthAdjust="spacingAndGlyphs"
								>
									{cow.data.sex}
								</text>
								<text
									fill="#563a1d"
									x="50%"
									y="40%"
									dominantBaseline="middle"
									textAnchor="middle"
									lengthAdjust="spacingAndGlyphs"
								>
									{defaultScore > 0 ? "Score " + defaultScore : cow.data.source}
								</text>
							</svg>
							<ChangeTag value={cow.data.tag} herd={herd} onChange={(tag) => updateValue("tag", tag)} />
						</Popover>
					</div>
					{hasScore && (
						<div>
							<FormGroup labelFor="score" label={"Score"} className={"fill"}>
								<ScoreInput
									onRelease={() => {
										setScoreClicked(true);
									}}
									onChange={(number) => setScore(number)}
									value={Number(score)}
								/>
								{scoreClicked && (
									<Button
										style={{ position: "relative", top: -5 }}
										intent={Intent.SUCCESS}
										icon={"tick"}
										onClick={saveScore}
									/>
								)}
							</FormGroup>
						</div>
					)}
				</div>

				<div>
					<div>
						{/*<FormGroup labelFor="type" label={"Type"} style={{ padding: 5, width: "50%" }}>
								<HTMLSelect
									style={{ width: "100%" }}
									className={".bp3-fill"}
									value={cow.data.type}
									onChange={(e) => updateValue("type", e.target.value)}
								>
									<option value={"Cow"}>Cow</option>
									<option value={"Calf"}>Calf</option>
									<option value={"Bull"}>Bull</option>
									<option value={"Stocker"}>Stocker</option>
								</HTMLSelect>
							</FormGroup>*/}
						{(cow.data.type === "Stocker" || cow.data.type === "Calf") && (
							<FormGroup
								labelFor="sex"
								label={"Sex"}
								style={{ padding: 5, width: cow.data.type !== "Stocker" ? "50%" : "100%" }}
							>
								<HTMLSelect
									style={{ width: "100%" }}
									className={".bp3-fill"}
									value={cow.data.sex}
									onChange={(e) => updateValue("sex", e.target.value)}
								>
									<option value={"Hef"}>Hef</option>
									<option value={"Bull"}>Bull</option>
									<option value={"Steer"}>Steer</option>
								</HTMLSelect>
							</FormGroup>
						)}
						<FormGroup
							labelFor="marking"
							label={"Marking"}
							style={{ padding: 5, width: cow.data.type !== "Stocker" ? "50%" : "100%" }}
						>
							<AddableInput
								value={cow.data.marking}
								onChange={(val) => updateValue("marking", val)}
								options={property.getMarkings()}
							/>
						</FormGroup>
						<FormGroup
							labelFor="origin"
							label={"Origin"}
							style={{ padding: 5, width: cow.data.type !== "Stocker" ? "50%" : "100%" }}
						>
							<AddableInput
								value={cow.data.source}
								onChange={(val) => updateValue("source", val)}
								options={property.getSources()}
							/>
						</FormGroup>
						{cow.getStatusOptions().length > 1 && (
							<FormGroup labelFor="status" label={"Status"} style={{ padding: 5, width: "50%" }}>
								<HTMLSelect
									className={".bp3-fill"}
									value={cow.data.status}
									onChange={(e) => updateValue("status", e.target.value)}
								>
									{cow.getStatusOptions().map((o) => (
										<option key={o} value={o}>
											{o}
										</option>
									))}
								</HTMLSelect>
							</FormGroup>
						)}

						{hasScore && (
							<div>
								<FormGroup labelFor="status" label={"Age"} className={"fill"}>
									<div
										style={{
											marginLeft: 15,
											width: "calc(100% - 30px)",
										}}
									>
										<Slider
											min={1}
											max={14}
											stepSize={1}
											vertical={false}
											labelStepSize={20}
											onRelease={(number) => updateValue("age", number)}
											onChange={(number) => setTempAge(number)}
											value={tempAge}
											labelRenderer={(number) => {
												if (number === 0) {
													return "";
												}
												return number;
											}}
										/>
									</div>
								</FormGroup>

								<FormGroup labelFor="status" label={"Temperament"} className={"fill"} style={{ paddingLeft: 0 }}>
									<div
										style={{
											marginLeft: 15,
											width: "calc(100% - 35px)",
										}}
									>
										<Slider
											min={1}
											max={5}
											stepSize={0.5}
											vertical={false}
											labelStepSize={1}
											onRelease={(number) => updateValue("temper", number)}
											onChange={(number) => setTempTemper(number)}
											value={tempTemper}
											labelRenderer={(number) => {
												if (number === 1) {
													return <Icon icon={"circle-arrow-up"} intent={Intent.SUCCESS} />;
												}
												if (number === 5) {
													return <Icon icon={"circle-arrow-down"} intent={Intent.DANGER} />;
												}
												return number;
											}}
										/>
									</div>
								</FormGroup>
							</div>
						)}
					</div>
				</div>

				<div style={{ width: 30, marginTop: 18 }}>{getOptions()}</div>
			</div>
			<div className={"clear"}></div>
			<br />

			{cow.data.type === "Cow" && (cow.data.status === C.STATUS.PREG || cow.data.calfs.length > 0) && (
				<div>
					<h3>
						<div className={"right"}>
							<Popover hasBackdrop={true} minimal={true} propToMonitor={property}>
								<Icon icon={"add"} iconSize={20} />
								<AddCalf property={property} inputCow={cow} addCalf={addCalf} replaceCow={replaceCow} />
							</Popover>
						</div>
						Active Calfs
					</h3>

					<table>
						<tbody>
							{cow.data.calfs.map((c) => {
								return (
									<tr key={`calf-${c.tag}`}>
										<td style={{ width: "25%" }}>{c.date}</td>
										<td style={{ width: "25%" }}>
											<span>{c.tag}</span>
										</td>
										<td>{c.birthWt}</td>
										<td>{c.weenWt}</td>
										<td style={{ width: "25%" }}>{c.createdBy}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			)}
			<br />
			<h3>History</h3>

			<div className={"flex"}>
				<Button
					style={{ flexGrow: 1, borderRadius: "8px 8px 0 0" }}
					icon={tab === "treatments" ? <Icon icon={"tick"} iconSize={10} /> : null}
					intent={tab === "treatments" ? Intent.PRIMARY : Intent.NONE}
					onClick={() => setTab("treatments")}
				>
					Treatments
				</Button>
				<Button
					style={{ flexGrow: 1, borderRadius: "8px 8px 0 0" }}
					icon={tab === "weights" ? <Icon icon={"tick"} iconSize={10} /> : null}
					intent={tab === "weights" ? Intent.PRIMARY : Intent.NONE}
					onClick={() => setTab("weights")}
				>
					Weights
				</Button>
				{hasScore && (
					<Button
						style={{ flexGrow: 1, borderRadius: "8px 8px 0 0" }}
						icon={tab === "scores" ? <Icon icon={"tick"} iconSize={10} /> : null}
						intent={tab === "scores" ? Intent.PRIMARY : Intent.NONE}
						onClick={() => setTab("scores")}
					>
						Scores
					</Button>
				)}
				<Button
					style={{ flexGrow: 1, borderRadius: "8px 8px 0 0" }}
					icon={tab === "changes" ? <Icon icon={"tick"} iconSize={10} /> : null}
					intent={tab === "changes" ? Intent.PRIMARY : Intent.NONE}
					onClick={() => setTab("changes")}
				>
					Change
				</Button>
			</div>
			<br />

			{tab === "weights" && (
				<table>
					<tbody>
						{cow.data.weight.length === 0 && (
							<tr>
								<td>No Weights</td>
							</tr>
						)}
						{cow.data.weight
							.filter((w) => !ParseBool(w.inactive))
							.map((w, idx) => (
								<tr key={`weight-${idx}`}>
									<td style={{ width: "25%" }}>{w.date}</td>
									<td style={{ width: "25%" }}>{w.type}</td>
									<td style={{ width: "25%" }}>
										<Popover hasBackdrop={true} minimal={true}>
											<div>{w.weight} lbs</div>
											<div>
												<Button
													className={Classes.POPOVER_DISMISS}
													intent={Intent.DANGER}
													onClick={() => {
														removeWeight(cow, w.type, w.weight);
													}}
												>
													Remove {w.weight}lbs
												</Button>
											</div>
										</Popover>
									</td>
									<td style={{ width: "25%" }}>{w.createdBy}</td>
								</tr>
							))}
					</tbody>
				</table>
			)}

			{tab === "scores" && (
				<table>
					<tbody>
						{cow.data.scores.length === 0 && (
							<tr>
								<td>No Scores</td>
							</tr>
						)}
						{cow.data.scores
							.filter((w) => !ParseBool(w.inactive))
							.map((w, idx) => (
								<tr key={`weight-${idx}`}>
									<td style={{ width: "25%" }}>{w.date}</td>
									<td style={{ width: "25%" }}>
										<Popover hasBackdrop={true} minimal={true}>
											<div>{w.score}</div>
											<div>
												<Button
													className={Classes.POPOVER_DISMISS}
													intent={Intent.DANGER}
													onClick={() => {
														//removeScore(cow, w.score);
													}}
												>
													Remove {w.score}
												</Button>
											</div>
										</Popover>
									</td>
									<td style={{ width: "25%" }}>{w.createdBy}</td>
								</tr>
							))}
					</tbody>
				</table>
			)}

			{tab === "treatments" && (
				<table>
					<tbody>
						{cow.data.treatments.length === 0 && (
							<tr>
								<td>No Treatments</td>
							</tr>
						)}
						{cow.data.treatments
							.filter((w) => !ParseBool(w.inactive))
							.map((t, idx) => (
								<tr key={`treatment-${idx}`}>
									<td style={{ width: "25%" }}>{t.date}</td>
									<td style={{ width: "25%" }}>
										<Icon icon={t.type !== "I" ? "shield" : "warning-sign"} />
									</td>
									<td style={{ width: "25%" }}>
										<Popover hasBackdrop={true} minimal={true}>
											<div>
												<span>{t.name}</span>
												{t.description && <div>{t.description}</div>}
											</div>
											<div>
												<Button
													className={Classes.POPOVER_DISMISS}
													intent={Intent.DANGER}
													onClick={() => {
														removeTreatment(cow, t.name, t.date);
													}}
												>
													Remove {t.name}
												</Button>
											</div>
										</Popover>
									</td>
									<td style={{ width: "25%" }}>{t.createdBy}</td>
								</tr>
							))}
					</tbody>
				</table>
			)}

			{tab === "changes" && (
				<table>
					<tbody>
						{cow.data.statusHistory.length === 0 && (
							<tr>
								<td>No Changes</td>
							</tr>
						)}
						{cow.data.statusHistory
							.slice()
							.reverse()
							.map((s, idx) => (
								<tr key={`history-${idx}`}>
									<td style={{ width: "25%" }}>{s.date}</td>
									<td style={{ width: "25%" }}>{s.field}</td>
									<td style={{ width: "25%" }}>{s.status}</td>
									<td style={{ width: "25%" }}>{s.createdBy}</td>
								</tr>
							))}
					</tbody>
				</table>
			)}

			<br />
			<br />
			<br />

			<div className={"flex buttons"}>
				<div style={{ flexGrow: 1 }}>
					<Popover hasBackdrop={true} minimal={true}>
						<Button icon={"trash"} intent={Intent.WARNING}>
							Delete
						</Button>
						<div style={{ padding: 10 }}>
							<p>This can not be undone!</p>
							<Button className={Classes.POPOVER_DISMISS}>Cancel</Button>
							<Button intent={Intent.DANGER} onClick={onRemoveCow} icon={"trash"}>
								Delete
							</Button>
						</div>
					</Popover>
				</div>
			</div>
			{/*</Swipeable>*/}

			{processingList && <ProcessingTable />}
		</Page>
	);
}

const mapStateToProps = createStructuredSelector({
	property: ranchSelectors.property,
	processingList: ranchSelectors.processingList,
	processingIdx: ranchSelectors.processingIdx,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			replaceCow: actions.replaceCow,
			removeCow: actions.removeCow,
			addWeight: actions.addWeight,
			addScore: actions.addScore,
			removeWeight: actions.removeWeight,
			addTreatment: actions.addTreatment,
			addCalf: actions.addCalf,
			setFinalStatus: actions.setFinalStatus,
			removeTreatment: actions.removeTreatment,
			changeHerd: actions.changeHerd,
			changeProcessingIdx: actions.changeProcessingIdx,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Cow);
