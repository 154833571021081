import React, { useEffect, useState } from "react";
import { Dialog } from "@blueprintjs/core";

function Popover({ id, defaultIsOpen, children, propToMonitor, onClick }) {
	const [isOpen, setIsOpen] = useState(defaultIsOpen);
	useEffect(() => {
		setIsOpen(defaultIsOpen);
	}, [propToMonitor]);

	const setOpen = () => {
		if (onClick) {
			onClick();
		}
		setIsOpen(true);
	};
	return (
		<span>
			<span onClick={setOpen}>{children[0]}</span>
			<Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
				{children[1]}
			</Dialog>
		</span>
	);
}
export default Popover;
