import React from "react";
import { FormGroup, InputGroup } from "@blueprintjs/core";
import CameraCapture from "./CameraCapture";

function WeightInput({ value, onChange, style }) {
	const filterImageResult = (results) => {
		if (!results || results.length === 0) {
			return;
		}
		onChange(Number(results[0]));
	};
	return (
		<FormGroup labelFor="weight" label={"Weight"} inline={true} style={style}>
			<div>
				<InputGroup
					type={"number"}
					id="weight"
					placeholder="lbs"
					value={value}
					onChange={(e) => onChange(e.target.value)}
					pattern={"[0-9.]*"}
					max={9999}
					min={0}
					inputMode="numeric"
				/>
			</div>
			<div style={{ position: "relative", left: 63, top: -25 }}>
				<CameraCapture setValue={filterImageResult} position={"auto"} />
			</div>
		</FormGroup>
	);
}

export default WeightInput;
