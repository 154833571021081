import User from "../api/User";
import { convertToClass } from "../api/utils";

const C = {
	NAME: "Account",
};

const initialState = {
	user: new User(),
	error: null,
};

export const types = {
	LOGIN: `${C.NAME}/LOGIN`,
	RELOGIN: `${C.NAME}/RELOGIN`,
	LOGIN_ERROR: `${C.NAME}/LOGIN_ERROR`,
	SET_USER: `${C.NAME}/SET_USER`,
};

export const actions = {
	login: (username, password) => (dispatch) => {
		const user = new User();
		user
			.login(username, password)
			.then(() => {
				dispatch({
					type: types.SET_USER,
					payload: user,
				});
			})
			.catch((err) => {
				dispatch({
					type: types.LOGIN_ERROR,
					payload: err,
				});
			});
	},
};

export const selectors = {
	user: (state) => convertToClass(state.account.user, User),
};

export default (state = initialState, action) => {
	switch (action.type) {
		case types.RELOGIN:
			return {
				...state,
				user: null,
			};
		case types.SET_USER:
			return {
				...state,
				user: action.payload,
			};
		case types.LOGIN_ERROR:
			return {
				...state,
				error: action.payload.message,
			};
		default:
			return state;
	}
};
