export default {
	STATUS: {
		PREG: "Preg",
		OPEN: "Open",
		DRY: "Dry",
		PAIR: "Pair",
		DEATH: "Death",
		SOLD: "Sold",
	},
	API: {
		URL: "/api/public/index.php",
	},
	STATE_NAME: "state.v2",
};
