import { Button, Collapse, InputGroup } from "@blueprintjs/core";
import Popover from "../components/Popover";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import C from "../Constants";
import AddCalf from "./components/AddCalf";
import { createStructuredSelector } from "reselect";
import { actions, selectors as ranchSelectors } from "./redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import CameraCapture from "./components/CameraCapture";

function Search({ cows = [], addCalf, replaceCow, property, hideCalf }) {
	const [search, setSearch] = useState("");
	const [found, setFound] = useState([]);
	const [exactFound, setExactFound] = useState(null);
	const [message, setMessage] = useState("");
	const [addCalfOpen, setAddCalfOpen] = useState(false);

	const history = useHistory();

	const clearSearch = () => {
		setSearch("");
		setFound([]);
		setExactFound(null);
		setMessage("");
	};

	const onSearchChange = (value, shouldNav) => {
		const exactResults = cows.filter((c) => c.data.tag.toLowerCase() === value.toLowerCase());
		const results = cows.filter((c) => c.data.tag.toLowerCase().includes(value.toLowerCase()));
		let exact = null;
		if (exactResults && exactResults.length === 1) {
			exact = exactResults[0];
		} else if (results && results.length === 1) {
			exact = results[0];
		}

		if (exact && shouldNav) {
			history.push(`/herd/${exact.data.herdID}/cow/${exact.data.guid}`);
		} else {
			setExactFound(exact);
		}

		setSearch(value);

		if (value === "" || results.length === 0) {
			setFound([]);
			return;
		}
		setFound(results.slice(0, 10));
	};

	const filterImageResult = (results) => {
		let found = results.find((ta) => cows.find((c) => c.data.tag === ta));
		if (!found) {
			found = results.find((ta) => cows.find((c) => ta.startsWith(c.data.tag)));
		}
		if (!found) {
			found = results.find((ta) => cows.find((c) => ta.includes(c.data.tag)));
		}

		if (!found) {
			setMessage("Tag not found - Text read " + results.join(", "));
			return;
		}

		onSearchChange(found, true);
	};

	const herdMap = property.getHerds().reduce((acc, h) => {
		acc[h.data.ID] = h.data.name;
		return acc;
	}, {});

	return (
		<div>
			<div style={{ display: "flex" }}>
				<div style={{ flexGrow: 1, marginRight: -25 }}>
					<InputGroup
						leftIcon={"search"}
						placeholder={"Search by tag"}
						value={search}
						onChange={(e) => onSearchChange(e.target.value)}
					/>
				</div>
				<div style={{ marginTop: 7, margin: 7, position: "relative", left: -20 }}>
					<CameraCapture setValue={filterImageResult} setMessage={setMessage} />
				</div>
				<div>
					{!hideCalf && (
						<Popover
							hasBackdrop={true}
							minimal={true}
							propToMonitor={addCalfOpen}
							onClick={() => {
								setAddCalfOpen(true);
							}}
						>
							<Button disabled={!exactFound || exactFound.data.status !== C.STATUS.PREG}>Add Calf</Button>
							<AddCalf
								property={property}
								inputCow={exactFound}
								addCalf={(calfHerdID, calf, mother) => {
									setAddCalfOpen(false);
									addCalf(calfHerdID, calf, mother);
								}}
								replaceCow={replaceCow}
							/>
						</Popover>
					)}
				</div>
				<div>
					{exactFound && (
						<NavLink to={`/herd/${exactFound.data.herdID}/cow/${exactFound.data.guid}`}>
							<Button rightIcon={"caret-right"} onClick={() => clearSearch()}>
								<span>{exactFound.data.tag}</span>
							</Button>
						</NavLink>
					)}
				</div>
				<div>{!exactFound && <Button disabled={true}>View</Button>}</div>
			</div>
			<Collapse isOpen={found.length > 0}>
				<div style={{ background: "white", border: "1px solid #ccc", padding: 5 }}>
					{found.map((f) => (
						<NavLink key={f.data.guid} to={`/herd/${f.data.herdID}/cow/${f.data.guid}`}>
							<div style={{ padding: 5 }} onClick={() => clearSearch()}>
								{f.data.tag} - {herdMap[f.data.herdID]}
							</div>
						</NavLink>
					))}
				</div>
			</Collapse>
			<div style={{ marginTop: -13, marginBottom: 13 }}>{message}</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	property: ranchSelectors.property,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			replaceCow: actions.replaceCow,
			addCalf: actions.addCalf,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Search);
