import React, { useEffect, useState } from "react";

import Popover from "../components/Popover";
import { Button, FormGroup, HTMLSelect, InputGroup, Intent, Spinner } from "@blueprintjs/core";
import { bindActionCreators } from "redux";
import { actions, selectors as ranchSelectors } from "./redux";
import { connect } from "react-redux";
import Page from "../components/Page";
import { createStructuredSelector } from "reselect";
import { NavLink, useHistory } from "react-router-dom";

import "./index.scss";
import * as Classes from "@blueprintjs/core/lib/cjs/common/classes";
import Search from "./Search";
import Summary from "./components/Summary";

import { useSwipeable, Swipeable } from "react-swipeable";

function Property({ property, match, createProperty, getProperty, removeProperty }) {
	const [herdName, setHerdName] = useState("");
	const [herdType, setHerdType] = useState("Cow");
	const [swipePct, setSwipePct] = useState(0);

	const history = useHistory();
	useEffect(() => {
		if (!property || Number(match.params.propertyID) !== Number(property.data.ID)) {
			getProperty(Number(match.params.propertyID));
			return;
		}
	}, [property, getProperty, match.params.propertyID]);

	useEffect(() => {
		if (!property || Number(match.params.propertyID) !== Number(property.data.ID)) {
			return;
		}

		const herds = property.getHerds();
		if (herds.length === 0) {
			history.push(`/property/${property.data.ID}/manage`);
		}
	}, [property]);

	if (!property || Number(match.params.propertyID) !== Number(property.data.ID)) {
		return (
			<Page>
				<p style={{ width: "100%", textAlign: "center" }}>Please wait while we switch properties...</p>
				<Spinner />
			</Page>
		);
	}

	const herds = property.getHerds();
	const summary = property.getSummary();
	const totalSummary = {};
	let grandTotal = 0;
	Object.keys(summary).forEach((k) => {
		if (!totalSummary[k]) {
			totalSummary[k] = 0;
		}
		totalSummary[k] += summary[k].total;
		grandTotal += summary[k].total;
	});

	const saveProperty = () => {
		createProperty(property.data.name, herdName, herdType);
		setHerdName("");
	};

	const displayItem = (herd) => {
		return (
			<div key={`property-${herd.data.ID}`} className={"property-item "}>
				<div className={"title no-border"}>
					<NavLink to={`/herd/${herd.data.ID}`}>
						<div className={"right"}>
							{herd.countCows()} {herd.data.type}
						</div>
						<div>{herd.data.name}</div>
					</NavLink>
				</div>
			</div>
		);
	};

	return (
		<Page header={`${property.data.name}`} className={"Property"} backLink={`/`}>
			<Search cows={property.getCows()} />
			<br />

			{/*<Swipeable
				style={{ position: "relative", left: swipePct + "%" }}
				delta={50}
				onSwipedRight={(eventData) => {
					if (eventData.deltaX < -50) {
						history.push("/");
					} else {
						setSwipePct(0);
					}
				}}
				onSwipedLeft={(eventData) => {}}
				onSwiping={(eventData) => {
					const pct = (-1 * eventData.deltaX) / 5;
					// left is disabled
					if (pct < 0) {
						setSwipePct(0);
						return;
					}

					if (pct > swipePct + 5 || pct < swipePct + 5) {
						setSwipePct(pct);
						return;
					}
				}}
			>*/}
			<div className={"property-item"}>
				<Summary
					total={grandTotal}
					dataSets={[
						[
							["Name", "Head", { role: "annotation" }],
							...Object.keys(totalSummary).map((k, i) => {
								return [k, totalSummary[k], totalSummary[k]];
							}),
						],
					]}
				/>
			</div>

			{herds.map((herd, idx) => {
				return displayItem(herd);
			})}

			<div>
				<NavLink to={`/property/${property.data.ID}/manage`}>
					<Button intent={Intent.WARNING}>Manage Property</Button>
				</NavLink>
			</div>
			{/*</Swipeable>*/}
		</Page>
	);
}

const mapStateToProps = createStructuredSelector({
	property: ranchSelectors.property,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getProperty: actions.getProperty,
			createProperty: actions.createProperty,
			removeProperty: actions.removeProperty,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Property);
