export const convertToClass = (obj, ClassName) => {
	if (!obj) {
		return null;
	}

	if (obj instanceof Array) {
		for (let i = 0; i < obj.length; i++) {
			obj[i] = convertToClass(obj[i], ClassName);
		}
		return obj;
	}

	if (obj instanceof ClassName) {
		return obj;
	}

	return new ClassName(obj.data ? obj.data : obj);
};
