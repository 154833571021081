import React, { useState } from "react";

import Popover from "../components/Popover";
import { Button, Checkbox, Icon, Intent } from "@blueprintjs/core";
import { bindActionCreators } from "redux";
import { actions, selectors as ranchSelectors } from "./redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";

import "./index.scss";
import AddTreatment from "./components/AddTreatment";

function BulkWorking({ property, replaceCows, herdID, processingList, setProcessingConfig }) {
	const herd = property.getHerd(herdID);

	const history = useHistory();
	const [treatments, setTreatments] = useState([]);
	const [wtRequired, setWtRequired] = useState(false);
	const [scoreRequired, setScoreRequired] = useState(false);

	const onSave = () => {
		const cows = herd.getCows();
		let toSave = cows
			.filter((c) => processingList.find((pl) => pl.guid === c.data.guid))
			.map((c) => {
				return {
					...c,
					data: {
						...c.data,
						newTreatments: treatments,
					},
				};
			});

		try {
			setProcessingConfig({
				requireWeight: wtRequired,
				requireScore: scoreRequired,
			});
			if (treatments.length > 0) {
				replaceCows(toSave);
			}
			history.push(`/herd/${herd.data.ID}/cow/${processingList[0].guid}`);
		} catch (e) {
			console.error(e);
			alert("Could not save, ask support");
		}
	};

	return (
		<div>
			<p>Select treatments to apply to the selected cattle, then hit process to apply.</p>

			<h3>
				<div className={"right"}>
					<Popover hasBackdrop={true} minimal={true}>
						<Icon icon={"add"} iconSize={20} />
						<AddTreatment
							property={property}
							addTreatment={(type, name, description) => {
								const newTreatments = [
									...treatments,
									{
										type,
										name,
										description,
									},
								];
								setTreatments(newTreatments);
							}}
						/>
					</Popover>
				</div>
				Treatments To Apply
			</h3>

			<div>
				<table>
					<tbody>
						{treatments.map((t, idx) => (
							<tr key={`treatment-${idx}`}>
								<td style={{ width: "1%", textAlign: "left" }}>
									<Icon icon={t.type !== "I" ? "shield" : "warning-sign"} />
								</td>
								<td style={{ width: "25%", textAlign: "left" }}>
									<Popover hasBackdrop={true} minimal={true}>
										<div>
											<span>{t.name}</span>
											{t.description && <div>{t.description}</div>}
										</div>
										<div>
											<Button
												intent={Intent.DANGER}
												onClick={() => {
													const clone = treatments.slice();
													clone.splice(idx, 1);
													setTreatments(clone);
												}}
											>
												Remove {t.name}
											</Button>
										</div>
									</Popover>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<h3>Required Fields</h3>
			<div>
				<Checkbox
					style={{ margin: 0, marginTop: 10 }}
					checked={wtRequired}
					onChange={(e) => setWtRequired(e.target.checked)}
				>
					Weight
				</Checkbox>
				<Checkbox
					style={{ margin: 0, marginTop: 10 }}
					checked={scoreRequired}
					onChange={(e) => setScoreRequired(e.target.checked)}
				>
					Body condition score
				</Checkbox>
			</div>

			<div className={"right"}>
				<Button intent={Intent.PRIMARY} onClick={onSave}>
					Start
				</Button>
			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	property: ranchSelectors.property,
	processingList: ranchSelectors.processingList,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			replaceCows: actions.replaceCows,
			setProcessingConfig: actions.setProcessingConfig,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(BulkWorking);
