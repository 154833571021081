export function ParseBool(input) {
	if (!input) {
		return false;
	}

	if (input === 0 || input === false) {
		return false;
	}

	if (input === true || input === "true") {
		return true;
	}

	if (input === "0" || input.toLowerCase() === "false") {
		return false;
	}
	return Boolean(input);
}
