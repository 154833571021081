import C from "../Constants";

class User {
	constructor(data) {
		this.data = {
			ID: 0,
			username: null,
			accountID: 0,
			...data,
		};
	}

	async login(username, password) {
		this.data.username = username;
		this.data.password = password;

		const result = await fetch(C.API.URL + "?method=login&XDEBUG_SESSION_START=PHPSTORM", {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(this),
		});

		const json = await result.json();
		this.data = {
			...json,
			username: username,
		};
	}

	isLoggedIn() {
		return this.data.ID > 0;
	}
}
export default User;
