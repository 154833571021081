import React, { useEffect, useState } from "react";

import { Button, InputGroup } from "@blueprintjs/core";
import { bindActionCreators } from "redux";
import { actions } from "./redux";
import { connect } from "react-redux";
import Page from "../components/Page";
import { useHistory } from "react-router-dom";

function Login({ onLogin }) {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const history = useHistory();
	useEffect(() => {
		history.push(`/`);
	}, [history]);

	return (
		<Page header={"Login"} className="Login">
			<div style={{ display: "flex", flexDirection: "column", margin: "20px 20px" }}>
				<InputGroup
					id="username"
					placeholder="Username"
					leftIcon={"user"}
					value={username}
					onChange={(e) => setUsername(e.target.value)}
				/>
				<br />
				<InputGroup
					id="password"
					placeholder="Password"
					type={"password"}
					leftIcon={"lock"}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<br />
				<Button onClick={() => onLogin(username, password)}>Submit</Button>
			</div>
		</Page>
	);
}

const mapStateToProps = ({ account }) => ({});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			onLogin: actions.login,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
