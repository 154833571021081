import React, { useEffect } from "react";
import { connect } from "react-redux";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";

import "./App.scss";
import Login from "./Account/Login";
import Property from "./Ranch/Property";
import PropertyManage from "./Ranch/PropertyManage";
import Company from "./Ranch/Company";
import { selectors as accountSelectors } from "./Account/redux";
import { createStructuredSelector } from "reselect";
import { Route, Switch, withRouter } from "react-router-dom";
import Herd from "./Ranch/Herd";
import Cow from "./Ranch/Cow";
import Log from "./Log";
import Processing from "./Ranch/Processing";
import { bindActionCreators } from "redux";

function App(props) {
	const { user, storeStatus, resave, needRefresh } = props;

	useEffect(() => {
		if (storeStatus !== "Error" && storeStatus !== "Saving") {
			return;
		}
		resave();
	}, []);

	const isLoggedIn = user && user.isLoggedIn();
	if (!isLoggedIn) {
		return <Login />;
	}

	return (
		<Switch>
			<Route exact path="/herd/:herdID/process/:tab" component={Processing} />
			<Route exact path="/herd/:herdID/cow/:cowID" component={Cow} />
			<Route exact path="/herd/:herdID" component={Herd} />
			<Route exact path="/property/:propertyID/manage" component={PropertyManage} />
			<Route exact path="/property/:propertyID" component={Property} />
			<Route exact path="/log" component={Log} />
			<Route exact path="/" component={Company} />
		</Switch>
	);
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			resave: () =>
				dispatch({
					type: "RESAVE",
					saveProperty: true,
					payload: "Error",
				}),
		},
		dispatch
	);

const mapStateToProps = createStructuredSelector({
	user: accountSelectors.user,
	storeStatus: (state) => state.storeStatus,
	needRefresh: (state) => state.needRefresh,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
