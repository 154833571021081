import moment from "moment";
import C from "../Constants";

class Cow {
	constructor(data = {}) {
		this.PRIMARY_KEYS = ["guid", "herdID"];
		this.data = {
			guid: null,
			tag: "",
			marking: "",
			type: "",
			status: "",
			treatments: [],
			weight: [],
			scores: [],
			calfs: [],
			momGuid: null,
			statusHistory: [],
			createdRancherID: 0,
			created: null,
			...data,
		};
	}

	addScore(score, createdBy) {
		this.data.scores.push({
			date: moment().format("YYYY-MM-DD"),
			createdBy: createdBy,
			score: score,
		});
	}

	addWeight(type, weight, createdBy) {
		this.data.weight.push({
			date: moment().format("YYYY-MM-DD"),
			createdBy: createdBy,
			type: type,
			weight: weight,
		});
	}

	removeWeight(type, weight, createdBy) {
		const foundIdx = this.data.weight.findIndex((t) => t.type === type && t.weight === weight);
		if (foundIdx === -1) {
			return;
		}

		this.data.weight[foundIdx].inactive = true;
	}

	addTreatment(type, name, description, createdBy) {
		this.data.treatments.push({
			date: moment().format("YYYY-MM-DD"),
			createdBy: createdBy,
			type: type,
			description: description,
			name: name,
		});
	}

	removeTreatment(name, date, createdBy) {
		const foundIdx = this.data.treatments.findIndex((t) => t.name === name && t.date === date);
		if (foundIdx === -1) {
			return;
		}

		this.data.treatments[foundIdx].inactive = true;
	}

	addCalf(calf, createdBy) {
		this.data.calfs.push({
			date: moment().format("YYYY-MM-DD"),
			createdBy: createdBy,
			herdID: calf.data.herdID,
			tag: calf.data.tag,
		});
	}

	getStatusOptions() {
		const result = [C.STATUS.OPEN];
		if (this.data.type === "Cow") {
			result.push(C.STATUS.PREG);
			result.push(C.STATUS.PAIR);
			result.push(C.STATUS.DRY);
		}
		if (this.data.type.startsWith("Calf")) {
			result.push(C.STATUS.PAIR);
		}
		return result;
	}
}
export default Cow;
