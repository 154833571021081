import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import C from "./Constants";
import { convertToClass } from "./api/utils";
import Property from "./api/Property";
import LZString from "lz-string";

const enhancers = [];
const middleware = [thunk];

if (process.env.NODE_ENV === "development") {
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

	if (typeof devToolsExtension === "function") {
		enhancers.push(devToolsExtension());
	}
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const diff = (oldProperty, newProperty) => {
	const r = deepDiff(oldProperty, newProperty);
	return r;
};

const deepDiff = (oldObj, newObj, primaryKeys = []) => {
	if (!oldObj) {
		return newObj;
	}

	let addAllPureProps = false;
	const pureProps = {};

	let hasAnyChange = false;
	let diffResult = {};
	const keys = Object.keys(newObj);
	for (const key of keys) {
		if (key === "PRIMARY_KEYS") {
			continue;
		}

		const newValue = newObj[key];
		const oldValue = oldObj[key];

		if (primaryKeys.includes(key)) {
			diffResult[key] = newValue;
			pureProps[key] = newValue;
			continue;
		}

		if (Array.isArray(newValue)) {
			for (let i = 0; i < newValue.length; i++) {
				if (newValue[i].skipSave) {
					continue;
				}

				let diffVal = null;
				if (!oldValue || !oldValue[i]) {
					diffVal = newValue[i];
				} else {
					diffVal = deepDiff(oldValue[i], newValue[i]);
				}
				if (!diffVal || Object.keys(diffVal).length === 0) {
					continue;
				}
				if (!diffResult[key]) {
					diffResult[key] = [];
				}
				diffResult[key].push(diffVal);
				hasAnyChange = true;
			}
			continue;
		}
		if (newValue instanceof Object) {
			const objResult = deepDiff(oldValue, newValue, newObj.PRIMARY_KEYS || []);
			if (!objResult || Object.keys(objResult).length === 0) {
				continue;
			}
			diffResult[key] = objResult;
			hasAnyChange = true;
			continue;
		}
		if (String(newValue) === String(oldValue)) {
			pureProps[key] = newValue;
			continue;
		}
		addAllPureProps = true;
		hasAnyChange = true;
		diffResult[key] = newValue;
		pureProps[key] = newValue;
	}

	if (!hasAnyChange) {
		return {};
	}

	if (addAllPureProps) {
		diffResult = { ...diffResult, ...pureProps };
	}
	return diffResult;
};

const decompress = (input) => {
	return LZString.decompress(input);
};

const compress = (input) => {
	return LZString.compress(input);
};

let previousLocalStore = {};
const putInLocalStorage = (key, state) => {
	const keyData = JSON.stringify(state);

	if (previousLocalStore[key] === keyData) {
		return;
	}

	try {
		localStorage.setItem(key, keyData);
	} catch (e) {
		console.error("Store failed", e);
	}
	previousLocalStore[key] = keyData;
};

const getFromLocalStorage = (key) => {
	try {
		const l = localStorage.getItem(key);
		if (!l) {
			return null;
		}

		previousLocalStore[key] = JSON.parse(l);
		return previousLocalStore[key];
	} catch (e) {
		console.error("Store failed", e);
	}
};

let resaveTimeout = null;
let previousResult = "{}";
export const saveToServer = (property, dispatch) => {
	console.log("Save to server triggered");
	if (resaveTimeout) {
		clearTimeout(resaveTimeout);
		resaveTimeout = null;
	}

	dispatch({
		type: "STATE_STATUS",
		payload: "Saving",
	});

	const prev = JSON.parse(previousResult);
	const result = diff(prev, property);
	const serializedState = JSON.stringify(result);

	console.log("saved state", serializedState);
	if (!serializedState || serializedState === "{}") {
		dispatch({
			type: "STATE_STATUS",
			payload: "Saved",
		});
		return;
	}

	fetch(C.API.URL + "?method=update-property&XDEBUG_SESSION_START=PHPSTORM", {
		method: "POST",
		mode: "cors",
		cache: "no-cache",
		credentials: "include",
		headers: {
			"Content-Type": "application/json",
		},
		body: serializedState,
	})
		.then((response) => {
			if (response.status === 401) {
				dispatch({
					type: "Account/RELOGIN",
				});
				return;
			}
			if (response.status !== 200) {
				dispatch({
					type: "STATE_STATUS",
					payload: "Error",
				});
				resaveTimeout = setTimeout(() => {
					dispatch({
						type: "RESAVE",
						saveProperty: true,
						payload: "Error",
					});
				}, 2000);
				return;
			}
			response
				.json()
				.then((jsonResult) => {
					if (jsonResult.changed) {
						console.log("Need to refresh");
						dispatch({
							type: "NEED_REFRESH",
							payload: true,
						});
					}
					property.data.lastUpdated = jsonResult.lastUpdated;
				})
				.catch((err) => {
					console.error("Failed to get json body", err);
				})
				.finally(() => {
					previousResult = JSON.stringify(property);
					dispatch({
						type: "STATE_STATUS",
						payload: "Saved",
					});
				});
		})
		.catch((e) => {
			console.error(e);
			dispatch({
				type: "STATE_STATUS",
				payload: "Error",
			});
			resaveTimeout = setTimeout(
				() =>
					dispatch({
						type: "RESAVE",
						saveProperty: true,
						payload: "Error",
					}),
				2000
			);
		});
};

let lastProperty = null;
let saveToServerTimeout = null;
const saveState = (state, dispatch) => {
	if (!state.lastAction) {
		return;
	}

	if (state.lastAction.wipeStorage) {
		clearTimeout(saveToServerTimeout);
		clearTimeout(resaveTimeout);

		lastProperty = null;
		previousResult = "{}";

		for (let key in window.localStorage) {
			if (!state.lastAction.wipeAccount && key === "account") {
				continue;
			}

			localStorage.removeItem(key);
		}

		if (state.lastAction.type === "LOGOUT") {
			dispatch({
				type: "RESET_ALL",
			});
		}
		return;
	}

	if (state.lastAction.type === "STATE_STATUS") {
		return;
	}

	putInLocalStorage("account", state.account);
	putInLocalStorage("storeStatus", state.storeStatus);
	putInLocalStorage("ranch.properties", state.ranch.properties);

	putInLocalStorage("ranch.processingList", state.ranch.processingList);
	putInLocalStorage("ranch.processingIdx", state.ranch.processingIdx);
	putInLocalStorage("ranch.processingConfig", state.ranch.processingConfig);

	if (state.lastAction.saveProperty) {
		try {
			if (state.ranch.property && state.ranch.property.data && state.ranch.property.data.herds) {
				for (let key in window.localStorage) {
					// Not a herd
					if (!key.startsWith("ranch.property.data.herds")) {
						continue;
					}

					// Herd of current property
					if (key.startsWith("ranch.property.data.herds[" + state.ranch.property.data.ID)) {
						continue;
					}

					// Herd of another property
					localStorage.removeItem(key);
				}
				state.ranch.property.data.herds.forEach((h, i) => {
					putInLocalStorage("ranch.property.data.herds[" + state.ranch.property.data.ID + ":" + i + "]", h);
				});
			}

			if (lastProperty === state.ranch.property.data.ID) {
				clearTimeout(saveToServerTimeout);
				saveToServerTimeout = window.setTimeout(() => {
					saveToServer(state.ranch.property, dispatch);

					if (state.lastAction.afterSaveCB) {
						state.lastAction.afterSaveCB();
					}
				}, 750);
			} else {
				previousResult = JSON.stringify(state.ranch.property);
				if (state.lastAction.afterSaveCB) {
					state.lastAction.afterSaveCB();
				}
			}

			lastProperty = state.ranch.property.data.ID;
		} catch (e) {
			console.error(e);

			// ignore write errors
			dispatch({
				type: "STATE_STATUS",
				payload: "Error",
			});
		}
	}

	if (state.ranch.property) {
		const saveProperty = {
			data: {
				...state.ranch.property.data,
				herds: [],
			},
		};
		putInLocalStorage("ranch.property", saveProperty);
	}
};

const loadState = () => {
	try {
		const state = {
			account: getFromLocalStorage("account") || {},
			storeStatus: getFromLocalStorage("storeStatus"),
			ranch: {
				properties: getFromLocalStorage("ranch.properties") || [],
				property: getFromLocalStorage("ranch.property") || {},
				processingList: getFromLocalStorage("ranch.processingList") || null,
				processingIdx: getFromLocalStorage("ranch.processingIdx") || 0,
				processingConfig: getFromLocalStorage("ranch.processingConfig") || null,
			},
		};
		if (state.ranch.property.data) {
			for (let key in window.localStorage) {
				if (!key.startsWith("ranch.property.data.herds[" + state.ranch.property.data.ID)) {
					continue;
				}

				state.ranch.property.data.herds.push(getFromLocalStorage(key) || {});
			}

			previousResult = JSON.stringify(state.ranch.property);
			lastProperty = state.ranch.property.data.ID;
		}
		return state;
	} catch (err) {
		return undefined;
	}
};

const persistedState = loadState();
export default function configureStore() {
	const store = createStore(rootReducer, persistedState, composedEnhancers);
	store.subscribe(() => {
		saveState(store.getState(), store.dispatch);
	});
	return store;
}
