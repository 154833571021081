import React, { useState } from "react";
import { Button, FormGroup, HTMLSelect, InputGroup } from "@blueprintjs/core";
import { Intent } from "@blueprintjs/core/lib/cjs/common/intent";
import { HuePicker } from "react-color";
import { createStructuredSelector } from "reselect";
import { actions, selectors as ranchSelectors } from "../redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const HerdManage = ({ property, herd, updateHerd }) => {
	if (!herd) {
		herd = {
			data: {
				name: "",
				sort: 1,
			},
		};
	}
	const [name, setName] = useState(herd.data.name);
	const [color, setColor] = useState(herd.data.color ? herd.data.color : "#ffe135");
	const [herdType, setHerdType] = useState(herd.data.type ? herd.data.type : "Cow");

	const saveHerd = () => {
		updateHerd({
			data: {
				...herd.data,
				color: color,
				type: herdType,
				name: name,
			},
		});
	};

	return (
		<div style={{ padding: 20, paddingTop: 0 }}>
			<h2>Manage Herd</h2>
			<FormGroup labelFor="herdName" label={"Herd Name"}>
				<InputGroup id="herdName" value={name} onChange={(e) => setName(e.target.value)} />
			</FormGroup>
			<br />
			<br />
			<FormGroup labelFor="type" label={"Herd Type"}>
				<HTMLSelect className={".bp3-fill"} value={herdType} onChange={(e) => setHerdType(e.target.value)}>
					<option value={"Cow"}>Cows</option>
					<option value={"Calf"}>Calfs</option>
					<option value={"Bull"}>Bulls</option>
					<option value={"Stocker"}>Stockers</option>
				</HTMLSelect>
			</FormGroup>
			<br />
			<br />
			<FormGroup labelFor="color" label={"Tag Color"}>
				<div style={{ padding: 15, background: color }}>
					<HuePicker color={color} onChange={(c) => setColor(c.hex)} />
				</div>
			</FormGroup>
			<br />
			<br />
			<div className={"right"}>
				<Button intent={Intent.PRIMARY} onClick={saveHerd}>
					Save
				</Button>
			</div>
		</div>
	);
};
const mapStateToProps = createStructuredSelector({
	property: ranchSelectors.property,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			updateHerd: actions.updateHerd,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(HerdManage);
